import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27e3cf07 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bc12f270 = () => interopDefault(import('../pages/coding/index.vue' /* webpackChunkName: "pages/coding/index" */))
const _1969072c = () => interopDefault(import('../pages/computernaya-gramotnost.vue' /* webpackChunkName: "pages/computernaya-gramotnost" */))
const _43c6e3ae = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _542bbf5e = () => interopDefault(import('../pages/ege-informatika/index.vue' /* webpackChunkName: "pages/ege-informatika/index" */))
const _aea9cc26 = () => interopDefault(import('../pages/frontend.vue' /* webpackChunkName: "pages/frontend" */))
const _0e29fcee = () => interopDefault(import('../pages/game-design.vue' /* webpackChunkName: "pages/game-design" */))
const _0e3af15e = () => interopDefault(import('../pages/graphic-design.vue' /* webpackChunkName: "pages/graphic-design" */))
const _cc0a88be = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _8d701d80 = () => interopDefault(import('../pages/mailing.vue' /* webpackChunkName: "pages/mailing" */))
const _00e271a6 = () => interopDefault(import('../pages/math/index.vue' /* webpackChunkName: "pages/math/index" */))
const _7031dffa = () => interopDefault(import('../pages/official_info/index.vue' /* webpackChunkName: "pages/official_info/index" */))
const _f897df7e = () => interopDefault(import('../pages/osnovy-programmirovaniya.vue' /* webpackChunkName: "pages/osnovy-programmirovaniya" */))
const _60de0a84 = () => interopDefault(import('../pages/predprinimatelstvo/index.vue' /* webpackChunkName: "pages/predprinimatelstvo/index" */))
const _3c47ff80 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _1b500712 = () => interopDefault(import('../pages/python.vue' /* webpackChunkName: "pages/python" */))
const _121f51ef = () => interopDefault(import('../pages/scratch.vue' /* webpackChunkName: "pages/scratch" */))
const _1ea0f62a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _3d3ef42a = () => interopDefault(import('../pages/unity.vue' /* webpackChunkName: "pages/unity" */))
const _97b60f26 = () => interopDefault(import('../pages/videoblogging.vue' /* webpackChunkName: "pages/videoblogging" */))
const _40480a92 = () => interopDefault(import('../pages/web-razrabotka.vue' /* webpackChunkName: "pages/web-razrabotka" */))
const _30e19abe = () => interopDefault(import('../pages/coding/camp/index.vue' /* webpackChunkName: "pages/coding/camp/index" */))
const _09806d6c = () => interopDefault(import('../pages/coding/creative/index.vue' /* webpackChunkName: "pages/coding/creative/index" */))
const _2a8f2c0f = () => interopDefault(import('../pages/coding/frontend/index.vue' /* webpackChunkName: "pages/coding/frontend/index" */))
const _4469dfa6 = () => interopDefault(import('../pages/coding/gamedesign/index.vue' /* webpackChunkName: "pages/coding/gamedesign/index" */))
const _431f43c2 = () => interopDefault(import('../pages/coding/graphicdesign/index.vue' /* webpackChunkName: "pages/coding/graphicdesign/index" */))
const _4468f334 = () => interopDefault(import('../pages/coding/junior/index.vue' /* webpackChunkName: "pages/coding/junior/index" */))
const _5a493830 = () => interopDefault(import('../pages/coding/pc/index.vue' /* webpackChunkName: "pages/coding/pc/index" */))
const _9059bc82 = () => interopDefault(import('../pages/coding/pytpro/index.vue' /* webpackChunkName: "pages/coding/pytpro/index" */))
const _2de1b918 = () => interopDefault(import('../pages/coding/pytstart/index.vue' /* webpackChunkName: "pages/coding/pytstart/index" */))
const _b96b53a4 = () => interopDefault(import('../pages/coding/unity/index.vue' /* webpackChunkName: "pages/coding/unity/index" */))
const _613a64ca = () => interopDefault(import('../pages/coding/videocontent/index.vue' /* webpackChunkName: "pages/coding/videocontent/index" */))
const _25cd19b5 = () => interopDefault(import('../pages/coding/websites/index.vue' /* webpackChunkName: "pages/coding/websites/index" */))
const _b9518d3c = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _6339df32 = () => interopDefault(import('../pages/payment/register.vue' /* webpackChunkName: "pages/payment/register" */))
const _7443ad36 = () => interopDefault(import('../pages/payment/status.vue' /* webpackChunkName: "pages/payment/status" */))
const _00583aef = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _0d6e22d8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/af",
    component: _27e3cf07,
    name: "index___af"
  }, {
    path: "/ar",
    component: _27e3cf07,
    name: "index___ar"
  }, {
    path: "/az",
    component: _27e3cf07,
    name: "index___az"
  }, {
    path: "/bg",
    component: _27e3cf07,
    name: "index___bg"
  }, {
    path: "/bs",
    component: _27e3cf07,
    name: "index___bs"
  }, {
    path: "/ca",
    component: _27e3cf07,
    name: "index___ca"
  }, {
    path: "/cis",
    component: _27e3cf07,
    name: "index___cis"
  }, {
    path: "/cr",
    component: _27e3cf07,
    name: "index___cr"
  }, {
    path: "/cs",
    component: _27e3cf07,
    name: "index___cs"
  }, {
    path: "/da",
    component: _27e3cf07,
    name: "index___da"
  }, {
    path: "/de",
    component: _27e3cf07,
    name: "index___de"
  }, {
    path: "/el",
    component: _27e3cf07,
    name: "index___el"
  }, {
    path: "/en",
    component: _27e3cf07,
    name: "index___en"
  }, {
    path: "/es",
    component: _27e3cf07,
    name: "index___es"
  }, {
    path: "/et",
    component: _27e3cf07,
    name: "index___et"
  }, {
    path: "/fr",
    component: _27e3cf07,
    name: "index___fr"
  }, {
    path: "/he",
    component: _27e3cf07,
    name: "index___he"
  }, {
    path: "/hr",
    component: _27e3cf07,
    name: "index___hr"
  }, {
    path: "/hu",
    component: _27e3cf07,
    name: "index___hu"
  }, {
    path: "/id",
    component: _27e3cf07,
    name: "index___id"
  }, {
    path: "/it",
    component: _27e3cf07,
    name: "index___it"
  }, {
    path: "/ka",
    component: _27e3cf07,
    name: "index___ka"
  }, {
    path: "/kh",
    component: _27e3cf07,
    name: "index___kh"
  }, {
    path: "/kk",
    component: _27e3cf07,
    name: "index___kk"
  }, {
    path: "/lt",
    component: _27e3cf07,
    name: "index___lt"
  }, {
    path: "/mk",
    component: _27e3cf07,
    name: "index___mk"
  }, {
    path: "/mn",
    component: _27e3cf07,
    name: "index___mn"
  }, {
    path: "/nl",
    component: _27e3cf07,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _27e3cf07,
    name: "index___pl"
  }, {
    path: "/pt",
    component: _27e3cf07,
    name: "index___pt"
  }, {
    path: "/ro",
    component: _27e3cf07,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _27e3cf07,
    name: "index___ru"
  }, {
    path: "/sk",
    component: _27e3cf07,
    name: "index___sk"
  }, {
    path: "/sq",
    component: _27e3cf07,
    name: "index___sq"
  }, {
    path: "/sr",
    component: _27e3cf07,
    name: "index___sr"
  }, {
    path: "/tg",
    component: _27e3cf07,
    name: "index___tg"
  }, {
    path: "/th",
    component: _27e3cf07,
    name: "index___th"
  }, {
    path: "/tr",
    component: _27e3cf07,
    name: "index___tr"
  }, {
    path: "/tt",
    component: _27e3cf07,
    name: "index___tt"
  }, {
    path: "/uk",
    component: _27e3cf07,
    name: "index___uk"
  }, {
    path: "/vi",
    component: _27e3cf07,
    name: "index___vi"
  }, {
    path: "/zh",
    component: _27e3cf07,
    name: "index___zh"
  }, {
    path: "/af/coding",
    component: _bc12f270,
    name: "coding___af"
  }, {
    path: "/af/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___af"
  }, {
    path: "/af/contacts",
    component: _43c6e3ae,
    name: "contacts___af"
  }, {
    path: "/af/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___af"
  }, {
    path: "/af/frontend",
    component: _aea9cc26,
    name: "frontend___af"
  }, {
    path: "/af/game-design",
    component: _0e29fcee,
    name: "game-design___af"
  }, {
    path: "/af/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___af"
  }, {
    path: "/af/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___af"
  }, {
    path: "/af/mailing",
    component: _8d701d80,
    name: "mailing___af"
  }, {
    path: "/af/math",
    component: _00e271a6,
    name: "math___af"
  }, {
    path: "/af/official_info",
    component: _7031dffa,
    name: "official_info___af"
  }, {
    path: "/af/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___af"
  }, {
    path: "/af/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___af"
  }, {
    path: "/af/privacy",
    component: _3c47ff80,
    name: "privacy___af"
  }, {
    path: "/af/python",
    component: _1b500712,
    name: "python___af"
  }, {
    path: "/af/scratch",
    component: _121f51ef,
    name: "scratch___af"
  }, {
    path: "/af/sitemap",
    component: _1ea0f62a,
    name: "sitemap___af"
  }, {
    path: "/af/unity",
    component: _3d3ef42a,
    name: "unity___af"
  }, {
    path: "/af/videoblogging",
    component: _97b60f26,
    name: "videoblogging___af"
  }, {
    path: "/af/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___af"
  }, {
    path: "/ar/coding",
    component: _bc12f270,
    name: "coding___ar"
  }, {
    path: "/ar/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___ar"
  }, {
    path: "/ar/contacts",
    component: _43c6e3ae,
    name: "contacts___ar"
  }, {
    path: "/ar/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___ar"
  }, {
    path: "/ar/frontend",
    component: _aea9cc26,
    name: "frontend___ar"
  }, {
    path: "/ar/game-design",
    component: _0e29fcee,
    name: "game-design___ar"
  }, {
    path: "/ar/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___ar"
  }, {
    path: "/ar/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___ar"
  }, {
    path: "/ar/mailing",
    component: _8d701d80,
    name: "mailing___ar"
  }, {
    path: "/ar/math",
    component: _00e271a6,
    name: "math___ar"
  }, {
    path: "/ar/official_info",
    component: _7031dffa,
    name: "official_info___ar"
  }, {
    path: "/ar/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___ar"
  }, {
    path: "/ar/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___ar"
  }, {
    path: "/ar/privacy",
    component: _3c47ff80,
    name: "privacy___ar"
  }, {
    path: "/ar/python",
    component: _1b500712,
    name: "python___ar"
  }, {
    path: "/ar/scratch",
    component: _121f51ef,
    name: "scratch___ar"
  }, {
    path: "/ar/sitemap",
    component: _1ea0f62a,
    name: "sitemap___ar"
  }, {
    path: "/ar/unity",
    component: _3d3ef42a,
    name: "unity___ar"
  }, {
    path: "/ar/videoblogging",
    component: _97b60f26,
    name: "videoblogging___ar"
  }, {
    path: "/ar/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___ar"
  }, {
    path: "/az/coding",
    component: _bc12f270,
    name: "coding___az"
  }, {
    path: "/az/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___az"
  }, {
    path: "/az/contacts",
    component: _43c6e3ae,
    name: "contacts___az"
  }, {
    path: "/az/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___az"
  }, {
    path: "/az/frontend",
    component: _aea9cc26,
    name: "frontend___az"
  }, {
    path: "/az/game-design",
    component: _0e29fcee,
    name: "game-design___az"
  }, {
    path: "/az/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___az"
  }, {
    path: "/az/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___az"
  }, {
    path: "/az/mailing",
    component: _8d701d80,
    name: "mailing___az"
  }, {
    path: "/az/math",
    component: _00e271a6,
    name: "math___az"
  }, {
    path: "/az/official_info",
    component: _7031dffa,
    name: "official_info___az"
  }, {
    path: "/az/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___az"
  }, {
    path: "/az/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___az"
  }, {
    path: "/az/privacy",
    component: _3c47ff80,
    name: "privacy___az"
  }, {
    path: "/az/python",
    component: _1b500712,
    name: "python___az"
  }, {
    path: "/az/scratch",
    component: _121f51ef,
    name: "scratch___az"
  }, {
    path: "/az/sitemap",
    component: _1ea0f62a,
    name: "sitemap___az"
  }, {
    path: "/az/unity",
    component: _3d3ef42a,
    name: "unity___az"
  }, {
    path: "/az/videoblogging",
    component: _97b60f26,
    name: "videoblogging___az"
  }, {
    path: "/az/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___az"
  }, {
    path: "/bg/coding",
    component: _bc12f270,
    name: "coding___bg"
  }, {
    path: "/bg/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___bg"
  }, {
    path: "/bg/contacts",
    component: _43c6e3ae,
    name: "contacts___bg"
  }, {
    path: "/bg/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___bg"
  }, {
    path: "/bg/frontend",
    component: _aea9cc26,
    name: "frontend___bg"
  }, {
    path: "/bg/game-design",
    component: _0e29fcee,
    name: "game-design___bg"
  }, {
    path: "/bg/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___bg"
  }, {
    path: "/bg/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___bg"
  }, {
    path: "/bg/mailing",
    component: _8d701d80,
    name: "mailing___bg"
  }, {
    path: "/bg/math",
    component: _00e271a6,
    name: "math___bg"
  }, {
    path: "/bg/official_info",
    component: _7031dffa,
    name: "official_info___bg"
  }, {
    path: "/bg/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___bg"
  }, {
    path: "/bg/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___bg"
  }, {
    path: "/bg/privacy",
    component: _3c47ff80,
    name: "privacy___bg"
  }, {
    path: "/bg/python",
    component: _1b500712,
    name: "python___bg"
  }, {
    path: "/bg/scratch",
    component: _121f51ef,
    name: "scratch___bg"
  }, {
    path: "/bg/sitemap",
    component: _1ea0f62a,
    name: "sitemap___bg"
  }, {
    path: "/bg/unity",
    component: _3d3ef42a,
    name: "unity___bg"
  }, {
    path: "/bg/videoblogging",
    component: _97b60f26,
    name: "videoblogging___bg"
  }, {
    path: "/bg/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___bg"
  }, {
    path: "/bs/coding",
    component: _bc12f270,
    name: "coding___bs"
  }, {
    path: "/bs/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___bs"
  }, {
    path: "/bs/contacts",
    component: _43c6e3ae,
    name: "contacts___bs"
  }, {
    path: "/bs/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___bs"
  }, {
    path: "/bs/frontend",
    component: _aea9cc26,
    name: "frontend___bs"
  }, {
    path: "/bs/game-design",
    component: _0e29fcee,
    name: "game-design___bs"
  }, {
    path: "/bs/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___bs"
  }, {
    path: "/bs/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___bs"
  }, {
    path: "/bs/mailing",
    component: _8d701d80,
    name: "mailing___bs"
  }, {
    path: "/bs/math",
    component: _00e271a6,
    name: "math___bs"
  }, {
    path: "/bs/official_info",
    component: _7031dffa,
    name: "official_info___bs"
  }, {
    path: "/bs/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___bs"
  }, {
    path: "/bs/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___bs"
  }, {
    path: "/bs/privacy",
    component: _3c47ff80,
    name: "privacy___bs"
  }, {
    path: "/bs/python",
    component: _1b500712,
    name: "python___bs"
  }, {
    path: "/bs/scratch",
    component: _121f51ef,
    name: "scratch___bs"
  }, {
    path: "/bs/sitemap",
    component: _1ea0f62a,
    name: "sitemap___bs"
  }, {
    path: "/bs/unity",
    component: _3d3ef42a,
    name: "unity___bs"
  }, {
    path: "/bs/videoblogging",
    component: _97b60f26,
    name: "videoblogging___bs"
  }, {
    path: "/bs/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___bs"
  }, {
    path: "/ca/coding",
    component: _bc12f270,
    name: "coding___ca"
  }, {
    path: "/ca/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___ca"
  }, {
    path: "/ca/contacts",
    component: _43c6e3ae,
    name: "contacts___ca"
  }, {
    path: "/ca/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___ca"
  }, {
    path: "/ca/frontend",
    component: _aea9cc26,
    name: "frontend___ca"
  }, {
    path: "/ca/game-design",
    component: _0e29fcee,
    name: "game-design___ca"
  }, {
    path: "/ca/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___ca"
  }, {
    path: "/ca/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___ca"
  }, {
    path: "/ca/mailing",
    component: _8d701d80,
    name: "mailing___ca"
  }, {
    path: "/ca/math",
    component: _00e271a6,
    name: "math___ca"
  }, {
    path: "/ca/official_info",
    component: _7031dffa,
    name: "official_info___ca"
  }, {
    path: "/ca/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___ca"
  }, {
    path: "/ca/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___ca"
  }, {
    path: "/ca/privacy",
    component: _3c47ff80,
    name: "privacy___ca"
  }, {
    path: "/ca/python",
    component: _1b500712,
    name: "python___ca"
  }, {
    path: "/ca/scratch",
    component: _121f51ef,
    name: "scratch___ca"
  }, {
    path: "/ca/sitemap",
    component: _1ea0f62a,
    name: "sitemap___ca"
  }, {
    path: "/ca/unity",
    component: _3d3ef42a,
    name: "unity___ca"
  }, {
    path: "/ca/videoblogging",
    component: _97b60f26,
    name: "videoblogging___ca"
  }, {
    path: "/ca/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___ca"
  }, {
    path: "/cis/coding",
    component: _bc12f270,
    name: "coding___cis"
  }, {
    path: "/cis/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___cis"
  }, {
    path: "/cis/contacts",
    component: _43c6e3ae,
    name: "contacts___cis"
  }, {
    path: "/cis/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___cis"
  }, {
    path: "/cis/frontend",
    component: _aea9cc26,
    name: "frontend___cis"
  }, {
    path: "/cis/game-design",
    component: _0e29fcee,
    name: "game-design___cis"
  }, {
    path: "/cis/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___cis"
  }, {
    path: "/cis/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___cis"
  }, {
    path: "/cis/mailing",
    component: _8d701d80,
    name: "mailing___cis"
  }, {
    path: "/cis/math",
    component: _00e271a6,
    name: "math___cis"
  }, {
    path: "/cis/official_info",
    component: _7031dffa,
    name: "official_info___cis"
  }, {
    path: "/cis/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___cis"
  }, {
    path: "/cis/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___cis"
  }, {
    path: "/cis/privacy",
    component: _3c47ff80,
    name: "privacy___cis"
  }, {
    path: "/cis/python",
    component: _1b500712,
    name: "python___cis"
  }, {
    path: "/cis/scratch",
    component: _121f51ef,
    name: "scratch___cis"
  }, {
    path: "/cis/sitemap",
    component: _1ea0f62a,
    name: "sitemap___cis"
  }, {
    path: "/cis/unity",
    component: _3d3ef42a,
    name: "unity___cis"
  }, {
    path: "/cis/videoblogging",
    component: _97b60f26,
    name: "videoblogging___cis"
  }, {
    path: "/cis/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___cis"
  }, {
    path: "/cr/coding",
    component: _bc12f270,
    name: "coding___cr"
  }, {
    path: "/cr/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___cr"
  }, {
    path: "/cr/contacts",
    component: _43c6e3ae,
    name: "contacts___cr"
  }, {
    path: "/cr/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___cr"
  }, {
    path: "/cr/frontend",
    component: _aea9cc26,
    name: "frontend___cr"
  }, {
    path: "/cr/game-design",
    component: _0e29fcee,
    name: "game-design___cr"
  }, {
    path: "/cr/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___cr"
  }, {
    path: "/cr/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___cr"
  }, {
    path: "/cr/mailing",
    component: _8d701d80,
    name: "mailing___cr"
  }, {
    path: "/cr/math",
    component: _00e271a6,
    name: "math___cr"
  }, {
    path: "/cr/official_info",
    component: _7031dffa,
    name: "official_info___cr"
  }, {
    path: "/cr/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___cr"
  }, {
    path: "/cr/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___cr"
  }, {
    path: "/cr/privacy",
    component: _3c47ff80,
    name: "privacy___cr"
  }, {
    path: "/cr/python",
    component: _1b500712,
    name: "python___cr"
  }, {
    path: "/cr/scratch",
    component: _121f51ef,
    name: "scratch___cr"
  }, {
    path: "/cr/sitemap",
    component: _1ea0f62a,
    name: "sitemap___cr"
  }, {
    path: "/cr/unity",
    component: _3d3ef42a,
    name: "unity___cr"
  }, {
    path: "/cr/videoblogging",
    component: _97b60f26,
    name: "videoblogging___cr"
  }, {
    path: "/cr/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___cr"
  }, {
    path: "/cs/coding",
    component: _bc12f270,
    name: "coding___cs"
  }, {
    path: "/cs/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___cs"
  }, {
    path: "/cs/contacts",
    component: _43c6e3ae,
    name: "contacts___cs"
  }, {
    path: "/cs/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___cs"
  }, {
    path: "/cs/frontend",
    component: _aea9cc26,
    name: "frontend___cs"
  }, {
    path: "/cs/game-design",
    component: _0e29fcee,
    name: "game-design___cs"
  }, {
    path: "/cs/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___cs"
  }, {
    path: "/cs/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___cs"
  }, {
    path: "/cs/mailing",
    component: _8d701d80,
    name: "mailing___cs"
  }, {
    path: "/cs/math",
    component: _00e271a6,
    name: "math___cs"
  }, {
    path: "/cs/official_info",
    component: _7031dffa,
    name: "official_info___cs"
  }, {
    path: "/cs/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___cs"
  }, {
    path: "/cs/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___cs"
  }, {
    path: "/cs/privacy",
    component: _3c47ff80,
    name: "privacy___cs"
  }, {
    path: "/cs/python",
    component: _1b500712,
    name: "python___cs"
  }, {
    path: "/cs/scratch",
    component: _121f51ef,
    name: "scratch___cs"
  }, {
    path: "/cs/sitemap",
    component: _1ea0f62a,
    name: "sitemap___cs"
  }, {
    path: "/cs/unity",
    component: _3d3ef42a,
    name: "unity___cs"
  }, {
    path: "/cs/videoblogging",
    component: _97b60f26,
    name: "videoblogging___cs"
  }, {
    path: "/cs/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___cs"
  }, {
    path: "/da/coding",
    component: _bc12f270,
    name: "coding___da"
  }, {
    path: "/da/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___da"
  }, {
    path: "/da/contacts",
    component: _43c6e3ae,
    name: "contacts___da"
  }, {
    path: "/da/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___da"
  }, {
    path: "/da/frontend",
    component: _aea9cc26,
    name: "frontend___da"
  }, {
    path: "/da/game-design",
    component: _0e29fcee,
    name: "game-design___da"
  }, {
    path: "/da/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___da"
  }, {
    path: "/da/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___da"
  }, {
    path: "/da/mailing",
    component: _8d701d80,
    name: "mailing___da"
  }, {
    path: "/da/math",
    component: _00e271a6,
    name: "math___da"
  }, {
    path: "/da/official_info",
    component: _7031dffa,
    name: "official_info___da"
  }, {
    path: "/da/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___da"
  }, {
    path: "/da/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___da"
  }, {
    path: "/da/privacy",
    component: _3c47ff80,
    name: "privacy___da"
  }, {
    path: "/da/python",
    component: _1b500712,
    name: "python___da"
  }, {
    path: "/da/scratch",
    component: _121f51ef,
    name: "scratch___da"
  }, {
    path: "/da/sitemap",
    component: _1ea0f62a,
    name: "sitemap___da"
  }, {
    path: "/da/unity",
    component: _3d3ef42a,
    name: "unity___da"
  }, {
    path: "/da/videoblogging",
    component: _97b60f26,
    name: "videoblogging___da"
  }, {
    path: "/da/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___da"
  }, {
    path: "/de/coding",
    component: _bc12f270,
    name: "coding___de"
  }, {
    path: "/de/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___de"
  }, {
    path: "/de/contacts",
    component: _43c6e3ae,
    name: "contacts___de"
  }, {
    path: "/de/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___de"
  }, {
    path: "/de/frontend",
    component: _aea9cc26,
    name: "frontend___de"
  }, {
    path: "/de/game-design",
    component: _0e29fcee,
    name: "game-design___de"
  }, {
    path: "/de/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___de"
  }, {
    path: "/de/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___de"
  }, {
    path: "/de/mailing",
    component: _8d701d80,
    name: "mailing___de"
  }, {
    path: "/de/math",
    component: _00e271a6,
    name: "math___de"
  }, {
    path: "/de/official_info",
    component: _7031dffa,
    name: "official_info___de"
  }, {
    path: "/de/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___de"
  }, {
    path: "/de/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___de"
  }, {
    path: "/de/privacy",
    component: _3c47ff80,
    name: "privacy___de"
  }, {
    path: "/de/python",
    component: _1b500712,
    name: "python___de"
  }, {
    path: "/de/scratch",
    component: _121f51ef,
    name: "scratch___de"
  }, {
    path: "/de/sitemap",
    component: _1ea0f62a,
    name: "sitemap___de"
  }, {
    path: "/de/unity",
    component: _3d3ef42a,
    name: "unity___de"
  }, {
    path: "/de/videoblogging",
    component: _97b60f26,
    name: "videoblogging___de"
  }, {
    path: "/de/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___de"
  }, {
    path: "/el/coding",
    component: _bc12f270,
    name: "coding___el"
  }, {
    path: "/el/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___el"
  }, {
    path: "/el/contacts",
    component: _43c6e3ae,
    name: "contacts___el"
  }, {
    path: "/el/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___el"
  }, {
    path: "/el/frontend",
    component: _aea9cc26,
    name: "frontend___el"
  }, {
    path: "/el/game-design",
    component: _0e29fcee,
    name: "game-design___el"
  }, {
    path: "/el/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___el"
  }, {
    path: "/el/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___el"
  }, {
    path: "/el/mailing",
    component: _8d701d80,
    name: "mailing___el"
  }, {
    path: "/el/math",
    component: _00e271a6,
    name: "math___el"
  }, {
    path: "/el/official_info",
    component: _7031dffa,
    name: "official_info___el"
  }, {
    path: "/el/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___el"
  }, {
    path: "/el/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___el"
  }, {
    path: "/el/privacy",
    component: _3c47ff80,
    name: "privacy___el"
  }, {
    path: "/el/python",
    component: _1b500712,
    name: "python___el"
  }, {
    path: "/el/scratch",
    component: _121f51ef,
    name: "scratch___el"
  }, {
    path: "/el/sitemap",
    component: _1ea0f62a,
    name: "sitemap___el"
  }, {
    path: "/el/unity",
    component: _3d3ef42a,
    name: "unity___el"
  }, {
    path: "/el/videoblogging",
    component: _97b60f26,
    name: "videoblogging___el"
  }, {
    path: "/el/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___el"
  }, {
    path: "/en/coding",
    component: _bc12f270,
    name: "coding___en"
  }, {
    path: "/en/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___en"
  }, {
    path: "/en/contacts",
    component: _43c6e3ae,
    name: "contacts___en"
  }, {
    path: "/en/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___en"
  }, {
    path: "/en/frontend",
    component: _aea9cc26,
    name: "frontend___en"
  }, {
    path: "/en/game-design",
    component: _0e29fcee,
    name: "game-design___en"
  }, {
    path: "/en/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___en"
  }, {
    path: "/en/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___en"
  }, {
    path: "/en/mailing",
    component: _8d701d80,
    name: "mailing___en"
  }, {
    path: "/en/math",
    component: _00e271a6,
    name: "math___en"
  }, {
    path: "/en/official_info",
    component: _7031dffa,
    name: "official_info___en"
  }, {
    path: "/en/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___en"
  }, {
    path: "/en/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___en"
  }, {
    path: "/en/privacy",
    component: _3c47ff80,
    name: "privacy___en"
  }, {
    path: "/en/python",
    component: _1b500712,
    name: "python___en"
  }, {
    path: "/en/scratch",
    component: _121f51ef,
    name: "scratch___en"
  }, {
    path: "/en/sitemap",
    component: _1ea0f62a,
    name: "sitemap___en"
  }, {
    path: "/en/unity",
    component: _3d3ef42a,
    name: "unity___en"
  }, {
    path: "/en/videoblogging",
    component: _97b60f26,
    name: "videoblogging___en"
  }, {
    path: "/en/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___en"
  }, {
    path: "/es/coding",
    component: _bc12f270,
    name: "coding___es"
  }, {
    path: "/es/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___es"
  }, {
    path: "/es/contacts",
    component: _43c6e3ae,
    name: "contacts___es"
  }, {
    path: "/es/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___es"
  }, {
    path: "/es/frontend",
    component: _aea9cc26,
    name: "frontend___es"
  }, {
    path: "/es/game-design",
    component: _0e29fcee,
    name: "game-design___es"
  }, {
    path: "/es/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___es"
  }, {
    path: "/es/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___es"
  }, {
    path: "/es/mailing",
    component: _8d701d80,
    name: "mailing___es"
  }, {
    path: "/es/math",
    component: _00e271a6,
    name: "math___es"
  }, {
    path: "/es/official_info",
    component: _7031dffa,
    name: "official_info___es"
  }, {
    path: "/es/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___es"
  }, {
    path: "/es/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___es"
  }, {
    path: "/es/privacy",
    component: _3c47ff80,
    name: "privacy___es"
  }, {
    path: "/es/python",
    component: _1b500712,
    name: "python___es"
  }, {
    path: "/es/scratch",
    component: _121f51ef,
    name: "scratch___es"
  }, {
    path: "/es/sitemap",
    component: _1ea0f62a,
    name: "sitemap___es"
  }, {
    path: "/es/unity",
    component: _3d3ef42a,
    name: "unity___es"
  }, {
    path: "/es/videoblogging",
    component: _97b60f26,
    name: "videoblogging___es"
  }, {
    path: "/es/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___es"
  }, {
    path: "/et/coding",
    component: _bc12f270,
    name: "coding___et"
  }, {
    path: "/et/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___et"
  }, {
    path: "/et/contacts",
    component: _43c6e3ae,
    name: "contacts___et"
  }, {
    path: "/et/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___et"
  }, {
    path: "/et/frontend",
    component: _aea9cc26,
    name: "frontend___et"
  }, {
    path: "/et/game-design",
    component: _0e29fcee,
    name: "game-design___et"
  }, {
    path: "/et/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___et"
  }, {
    path: "/et/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___et"
  }, {
    path: "/et/mailing",
    component: _8d701d80,
    name: "mailing___et"
  }, {
    path: "/et/math",
    component: _00e271a6,
    name: "math___et"
  }, {
    path: "/et/official_info",
    component: _7031dffa,
    name: "official_info___et"
  }, {
    path: "/et/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___et"
  }, {
    path: "/et/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___et"
  }, {
    path: "/et/privacy",
    component: _3c47ff80,
    name: "privacy___et"
  }, {
    path: "/et/python",
    component: _1b500712,
    name: "python___et"
  }, {
    path: "/et/scratch",
    component: _121f51ef,
    name: "scratch___et"
  }, {
    path: "/et/sitemap",
    component: _1ea0f62a,
    name: "sitemap___et"
  }, {
    path: "/et/unity",
    component: _3d3ef42a,
    name: "unity___et"
  }, {
    path: "/et/videoblogging",
    component: _97b60f26,
    name: "videoblogging___et"
  }, {
    path: "/et/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___et"
  }, {
    path: "/fr/coding",
    component: _bc12f270,
    name: "coding___fr"
  }, {
    path: "/fr/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___fr"
  }, {
    path: "/fr/contacts",
    component: _43c6e3ae,
    name: "contacts___fr"
  }, {
    path: "/fr/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___fr"
  }, {
    path: "/fr/frontend",
    component: _aea9cc26,
    name: "frontend___fr"
  }, {
    path: "/fr/game-design",
    component: _0e29fcee,
    name: "game-design___fr"
  }, {
    path: "/fr/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___fr"
  }, {
    path: "/fr/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___fr"
  }, {
    path: "/fr/mailing",
    component: _8d701d80,
    name: "mailing___fr"
  }, {
    path: "/fr/math",
    component: _00e271a6,
    name: "math___fr"
  }, {
    path: "/fr/official_info",
    component: _7031dffa,
    name: "official_info___fr"
  }, {
    path: "/fr/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___fr"
  }, {
    path: "/fr/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___fr"
  }, {
    path: "/fr/privacy",
    component: _3c47ff80,
    name: "privacy___fr"
  }, {
    path: "/fr/python",
    component: _1b500712,
    name: "python___fr"
  }, {
    path: "/fr/scratch",
    component: _121f51ef,
    name: "scratch___fr"
  }, {
    path: "/fr/sitemap",
    component: _1ea0f62a,
    name: "sitemap___fr"
  }, {
    path: "/fr/unity",
    component: _3d3ef42a,
    name: "unity___fr"
  }, {
    path: "/fr/videoblogging",
    component: _97b60f26,
    name: "videoblogging___fr"
  }, {
    path: "/fr/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___fr"
  }, {
    path: "/he/coding",
    component: _bc12f270,
    name: "coding___he"
  }, {
    path: "/he/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___he"
  }, {
    path: "/he/contacts",
    component: _43c6e3ae,
    name: "contacts___he"
  }, {
    path: "/he/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___he"
  }, {
    path: "/he/frontend",
    component: _aea9cc26,
    name: "frontend___he"
  }, {
    path: "/he/game-design",
    component: _0e29fcee,
    name: "game-design___he"
  }, {
    path: "/he/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___he"
  }, {
    path: "/he/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___he"
  }, {
    path: "/he/mailing",
    component: _8d701d80,
    name: "mailing___he"
  }, {
    path: "/he/math",
    component: _00e271a6,
    name: "math___he"
  }, {
    path: "/he/official_info",
    component: _7031dffa,
    name: "official_info___he"
  }, {
    path: "/he/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___he"
  }, {
    path: "/he/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___he"
  }, {
    path: "/he/privacy",
    component: _3c47ff80,
    name: "privacy___he"
  }, {
    path: "/he/python",
    component: _1b500712,
    name: "python___he"
  }, {
    path: "/he/scratch",
    component: _121f51ef,
    name: "scratch___he"
  }, {
    path: "/he/sitemap",
    component: _1ea0f62a,
    name: "sitemap___he"
  }, {
    path: "/he/unity",
    component: _3d3ef42a,
    name: "unity___he"
  }, {
    path: "/he/videoblogging",
    component: _97b60f26,
    name: "videoblogging___he"
  }, {
    path: "/he/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___he"
  }, {
    path: "/hr/coding",
    component: _bc12f270,
    name: "coding___hr"
  }, {
    path: "/hr/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___hr"
  }, {
    path: "/hr/contacts",
    component: _43c6e3ae,
    name: "contacts___hr"
  }, {
    path: "/hr/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___hr"
  }, {
    path: "/hr/frontend",
    component: _aea9cc26,
    name: "frontend___hr"
  }, {
    path: "/hr/game-design",
    component: _0e29fcee,
    name: "game-design___hr"
  }, {
    path: "/hr/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___hr"
  }, {
    path: "/hr/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___hr"
  }, {
    path: "/hr/mailing",
    component: _8d701d80,
    name: "mailing___hr"
  }, {
    path: "/hr/math",
    component: _00e271a6,
    name: "math___hr"
  }, {
    path: "/hr/official_info",
    component: _7031dffa,
    name: "official_info___hr"
  }, {
    path: "/hr/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___hr"
  }, {
    path: "/hr/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___hr"
  }, {
    path: "/hr/privacy",
    component: _3c47ff80,
    name: "privacy___hr"
  }, {
    path: "/hr/python",
    component: _1b500712,
    name: "python___hr"
  }, {
    path: "/hr/scratch",
    component: _121f51ef,
    name: "scratch___hr"
  }, {
    path: "/hr/sitemap",
    component: _1ea0f62a,
    name: "sitemap___hr"
  }, {
    path: "/hr/unity",
    component: _3d3ef42a,
    name: "unity___hr"
  }, {
    path: "/hr/videoblogging",
    component: _97b60f26,
    name: "videoblogging___hr"
  }, {
    path: "/hr/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___hr"
  }, {
    path: "/hu/coding",
    component: _bc12f270,
    name: "coding___hu"
  }, {
    path: "/hu/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___hu"
  }, {
    path: "/hu/contacts",
    component: _43c6e3ae,
    name: "contacts___hu"
  }, {
    path: "/hu/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___hu"
  }, {
    path: "/hu/frontend",
    component: _aea9cc26,
    name: "frontend___hu"
  }, {
    path: "/hu/game-design",
    component: _0e29fcee,
    name: "game-design___hu"
  }, {
    path: "/hu/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___hu"
  }, {
    path: "/hu/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___hu"
  }, {
    path: "/hu/mailing",
    component: _8d701d80,
    name: "mailing___hu"
  }, {
    path: "/hu/math",
    component: _00e271a6,
    name: "math___hu"
  }, {
    path: "/hu/official_info",
    component: _7031dffa,
    name: "official_info___hu"
  }, {
    path: "/hu/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___hu"
  }, {
    path: "/hu/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___hu"
  }, {
    path: "/hu/privacy",
    component: _3c47ff80,
    name: "privacy___hu"
  }, {
    path: "/hu/python",
    component: _1b500712,
    name: "python___hu"
  }, {
    path: "/hu/scratch",
    component: _121f51ef,
    name: "scratch___hu"
  }, {
    path: "/hu/sitemap",
    component: _1ea0f62a,
    name: "sitemap___hu"
  }, {
    path: "/hu/unity",
    component: _3d3ef42a,
    name: "unity___hu"
  }, {
    path: "/hu/videoblogging",
    component: _97b60f26,
    name: "videoblogging___hu"
  }, {
    path: "/hu/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___hu"
  }, {
    path: "/id/coding",
    component: _bc12f270,
    name: "coding___id"
  }, {
    path: "/id/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___id"
  }, {
    path: "/id/contacts",
    component: _43c6e3ae,
    name: "contacts___id"
  }, {
    path: "/id/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___id"
  }, {
    path: "/id/frontend",
    component: _aea9cc26,
    name: "frontend___id"
  }, {
    path: "/id/game-design",
    component: _0e29fcee,
    name: "game-design___id"
  }, {
    path: "/id/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___id"
  }, {
    path: "/id/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___id"
  }, {
    path: "/id/mailing",
    component: _8d701d80,
    name: "mailing___id"
  }, {
    path: "/id/math",
    component: _00e271a6,
    name: "math___id"
  }, {
    path: "/id/official_info",
    component: _7031dffa,
    name: "official_info___id"
  }, {
    path: "/id/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___id"
  }, {
    path: "/id/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___id"
  }, {
    path: "/id/privacy",
    component: _3c47ff80,
    name: "privacy___id"
  }, {
    path: "/id/python",
    component: _1b500712,
    name: "python___id"
  }, {
    path: "/id/scratch",
    component: _121f51ef,
    name: "scratch___id"
  }, {
    path: "/id/sitemap",
    component: _1ea0f62a,
    name: "sitemap___id"
  }, {
    path: "/id/unity",
    component: _3d3ef42a,
    name: "unity___id"
  }, {
    path: "/id/videoblogging",
    component: _97b60f26,
    name: "videoblogging___id"
  }, {
    path: "/id/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___id"
  }, {
    path: "/it/coding",
    component: _bc12f270,
    name: "coding___it"
  }, {
    path: "/it/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___it"
  }, {
    path: "/it/contacts",
    component: _43c6e3ae,
    name: "contacts___it"
  }, {
    path: "/it/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___it"
  }, {
    path: "/it/frontend",
    component: _aea9cc26,
    name: "frontend___it"
  }, {
    path: "/it/game-design",
    component: _0e29fcee,
    name: "game-design___it"
  }, {
    path: "/it/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___it"
  }, {
    path: "/it/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___it"
  }, {
    path: "/it/mailing",
    component: _8d701d80,
    name: "mailing___it"
  }, {
    path: "/it/math",
    component: _00e271a6,
    name: "math___it"
  }, {
    path: "/it/official_info",
    component: _7031dffa,
    name: "official_info___it"
  }, {
    path: "/it/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___it"
  }, {
    path: "/it/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___it"
  }, {
    path: "/it/privacy",
    component: _3c47ff80,
    name: "privacy___it"
  }, {
    path: "/it/python",
    component: _1b500712,
    name: "python___it"
  }, {
    path: "/it/scratch",
    component: _121f51ef,
    name: "scratch___it"
  }, {
    path: "/it/sitemap",
    component: _1ea0f62a,
    name: "sitemap___it"
  }, {
    path: "/it/unity",
    component: _3d3ef42a,
    name: "unity___it"
  }, {
    path: "/it/videoblogging",
    component: _97b60f26,
    name: "videoblogging___it"
  }, {
    path: "/it/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___it"
  }, {
    path: "/ka/coding",
    component: _bc12f270,
    name: "coding___ka"
  }, {
    path: "/ka/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___ka"
  }, {
    path: "/ka/contacts",
    component: _43c6e3ae,
    name: "contacts___ka"
  }, {
    path: "/ka/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___ka"
  }, {
    path: "/ka/frontend",
    component: _aea9cc26,
    name: "frontend___ka"
  }, {
    path: "/ka/game-design",
    component: _0e29fcee,
    name: "game-design___ka"
  }, {
    path: "/ka/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___ka"
  }, {
    path: "/ka/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___ka"
  }, {
    path: "/ka/mailing",
    component: _8d701d80,
    name: "mailing___ka"
  }, {
    path: "/ka/math",
    component: _00e271a6,
    name: "math___ka"
  }, {
    path: "/ka/official_info",
    component: _7031dffa,
    name: "official_info___ka"
  }, {
    path: "/ka/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___ka"
  }, {
    path: "/ka/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___ka"
  }, {
    path: "/ka/privacy",
    component: _3c47ff80,
    name: "privacy___ka"
  }, {
    path: "/ka/python",
    component: _1b500712,
    name: "python___ka"
  }, {
    path: "/ka/scratch",
    component: _121f51ef,
    name: "scratch___ka"
  }, {
    path: "/ka/sitemap",
    component: _1ea0f62a,
    name: "sitemap___ka"
  }, {
    path: "/ka/unity",
    component: _3d3ef42a,
    name: "unity___ka"
  }, {
    path: "/ka/videoblogging",
    component: _97b60f26,
    name: "videoblogging___ka"
  }, {
    path: "/ka/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___ka"
  }, {
    path: "/kh/coding",
    component: _bc12f270,
    name: "coding___kh"
  }, {
    path: "/kh/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___kh"
  }, {
    path: "/kh/contacts",
    component: _43c6e3ae,
    name: "contacts___kh"
  }, {
    path: "/kh/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___kh"
  }, {
    path: "/kh/frontend",
    component: _aea9cc26,
    name: "frontend___kh"
  }, {
    path: "/kh/game-design",
    component: _0e29fcee,
    name: "game-design___kh"
  }, {
    path: "/kh/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___kh"
  }, {
    path: "/kh/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___kh"
  }, {
    path: "/kh/mailing",
    component: _8d701d80,
    name: "mailing___kh"
  }, {
    path: "/kh/math",
    component: _00e271a6,
    name: "math___kh"
  }, {
    path: "/kh/official_info",
    component: _7031dffa,
    name: "official_info___kh"
  }, {
    path: "/kh/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___kh"
  }, {
    path: "/kh/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___kh"
  }, {
    path: "/kh/privacy",
    component: _3c47ff80,
    name: "privacy___kh"
  }, {
    path: "/kh/python",
    component: _1b500712,
    name: "python___kh"
  }, {
    path: "/kh/scratch",
    component: _121f51ef,
    name: "scratch___kh"
  }, {
    path: "/kh/sitemap",
    component: _1ea0f62a,
    name: "sitemap___kh"
  }, {
    path: "/kh/unity",
    component: _3d3ef42a,
    name: "unity___kh"
  }, {
    path: "/kh/videoblogging",
    component: _97b60f26,
    name: "videoblogging___kh"
  }, {
    path: "/kh/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___kh"
  }, {
    path: "/kk/coding",
    component: _bc12f270,
    name: "coding___kk"
  }, {
    path: "/kk/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___kk"
  }, {
    path: "/kk/contacts",
    component: _43c6e3ae,
    name: "contacts___kk"
  }, {
    path: "/kk/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___kk"
  }, {
    path: "/kk/frontend",
    component: _aea9cc26,
    name: "frontend___kk"
  }, {
    path: "/kk/game-design",
    component: _0e29fcee,
    name: "game-design___kk"
  }, {
    path: "/kk/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___kk"
  }, {
    path: "/kk/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___kk"
  }, {
    path: "/kk/mailing",
    component: _8d701d80,
    name: "mailing___kk"
  }, {
    path: "/kk/math",
    component: _00e271a6,
    name: "math___kk"
  }, {
    path: "/kk/official_info",
    component: _7031dffa,
    name: "official_info___kk"
  }, {
    path: "/kk/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___kk"
  }, {
    path: "/kk/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___kk"
  }, {
    path: "/kk/privacy",
    component: _3c47ff80,
    name: "privacy___kk"
  }, {
    path: "/kk/python",
    component: _1b500712,
    name: "python___kk"
  }, {
    path: "/kk/scratch",
    component: _121f51ef,
    name: "scratch___kk"
  }, {
    path: "/kk/sitemap",
    component: _1ea0f62a,
    name: "sitemap___kk"
  }, {
    path: "/kk/unity",
    component: _3d3ef42a,
    name: "unity___kk"
  }, {
    path: "/kk/videoblogging",
    component: _97b60f26,
    name: "videoblogging___kk"
  }, {
    path: "/kk/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___kk"
  }, {
    path: "/lt/coding",
    component: _bc12f270,
    name: "coding___lt"
  }, {
    path: "/lt/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___lt"
  }, {
    path: "/lt/contacts",
    component: _43c6e3ae,
    name: "contacts___lt"
  }, {
    path: "/lt/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___lt"
  }, {
    path: "/lt/frontend",
    component: _aea9cc26,
    name: "frontend___lt"
  }, {
    path: "/lt/game-design",
    component: _0e29fcee,
    name: "game-design___lt"
  }, {
    path: "/lt/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___lt"
  }, {
    path: "/lt/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___lt"
  }, {
    path: "/lt/mailing",
    component: _8d701d80,
    name: "mailing___lt"
  }, {
    path: "/lt/math",
    component: _00e271a6,
    name: "math___lt"
  }, {
    path: "/lt/official_info",
    component: _7031dffa,
    name: "official_info___lt"
  }, {
    path: "/lt/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___lt"
  }, {
    path: "/lt/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___lt"
  }, {
    path: "/lt/privacy",
    component: _3c47ff80,
    name: "privacy___lt"
  }, {
    path: "/lt/python",
    component: _1b500712,
    name: "python___lt"
  }, {
    path: "/lt/scratch",
    component: _121f51ef,
    name: "scratch___lt"
  }, {
    path: "/lt/sitemap",
    component: _1ea0f62a,
    name: "sitemap___lt"
  }, {
    path: "/lt/unity",
    component: _3d3ef42a,
    name: "unity___lt"
  }, {
    path: "/lt/videoblogging",
    component: _97b60f26,
    name: "videoblogging___lt"
  }, {
    path: "/lt/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___lt"
  }, {
    path: "/mk/coding",
    component: _bc12f270,
    name: "coding___mk"
  }, {
    path: "/mk/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___mk"
  }, {
    path: "/mk/contacts",
    component: _43c6e3ae,
    name: "contacts___mk"
  }, {
    path: "/mk/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___mk"
  }, {
    path: "/mk/frontend",
    component: _aea9cc26,
    name: "frontend___mk"
  }, {
    path: "/mk/game-design",
    component: _0e29fcee,
    name: "game-design___mk"
  }, {
    path: "/mk/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___mk"
  }, {
    path: "/mk/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___mk"
  }, {
    path: "/mk/mailing",
    component: _8d701d80,
    name: "mailing___mk"
  }, {
    path: "/mk/math",
    component: _00e271a6,
    name: "math___mk"
  }, {
    path: "/mk/official_info",
    component: _7031dffa,
    name: "official_info___mk"
  }, {
    path: "/mk/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___mk"
  }, {
    path: "/mk/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___mk"
  }, {
    path: "/mk/privacy",
    component: _3c47ff80,
    name: "privacy___mk"
  }, {
    path: "/mk/python",
    component: _1b500712,
    name: "python___mk"
  }, {
    path: "/mk/scratch",
    component: _121f51ef,
    name: "scratch___mk"
  }, {
    path: "/mk/sitemap",
    component: _1ea0f62a,
    name: "sitemap___mk"
  }, {
    path: "/mk/unity",
    component: _3d3ef42a,
    name: "unity___mk"
  }, {
    path: "/mk/videoblogging",
    component: _97b60f26,
    name: "videoblogging___mk"
  }, {
    path: "/mk/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___mk"
  }, {
    path: "/mn/coding",
    component: _bc12f270,
    name: "coding___mn"
  }, {
    path: "/mn/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___mn"
  }, {
    path: "/mn/contacts",
    component: _43c6e3ae,
    name: "contacts___mn"
  }, {
    path: "/mn/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___mn"
  }, {
    path: "/mn/frontend",
    component: _aea9cc26,
    name: "frontend___mn"
  }, {
    path: "/mn/game-design",
    component: _0e29fcee,
    name: "game-design___mn"
  }, {
    path: "/mn/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___mn"
  }, {
    path: "/mn/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___mn"
  }, {
    path: "/mn/mailing",
    component: _8d701d80,
    name: "mailing___mn"
  }, {
    path: "/mn/math",
    component: _00e271a6,
    name: "math___mn"
  }, {
    path: "/mn/official_info",
    component: _7031dffa,
    name: "official_info___mn"
  }, {
    path: "/mn/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___mn"
  }, {
    path: "/mn/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___mn"
  }, {
    path: "/mn/privacy",
    component: _3c47ff80,
    name: "privacy___mn"
  }, {
    path: "/mn/python",
    component: _1b500712,
    name: "python___mn"
  }, {
    path: "/mn/scratch",
    component: _121f51ef,
    name: "scratch___mn"
  }, {
    path: "/mn/sitemap",
    component: _1ea0f62a,
    name: "sitemap___mn"
  }, {
    path: "/mn/unity",
    component: _3d3ef42a,
    name: "unity___mn"
  }, {
    path: "/mn/videoblogging",
    component: _97b60f26,
    name: "videoblogging___mn"
  }, {
    path: "/mn/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___mn"
  }, {
    path: "/nl/coding",
    component: _bc12f270,
    name: "coding___nl"
  }, {
    path: "/nl/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___nl"
  }, {
    path: "/nl/contacts",
    component: _43c6e3ae,
    name: "contacts___nl"
  }, {
    path: "/nl/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___nl"
  }, {
    path: "/nl/frontend",
    component: _aea9cc26,
    name: "frontend___nl"
  }, {
    path: "/nl/game-design",
    component: _0e29fcee,
    name: "game-design___nl"
  }, {
    path: "/nl/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___nl"
  }, {
    path: "/nl/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___nl"
  }, {
    path: "/nl/mailing",
    component: _8d701d80,
    name: "mailing___nl"
  }, {
    path: "/nl/math",
    component: _00e271a6,
    name: "math___nl"
  }, {
    path: "/nl/official_info",
    component: _7031dffa,
    name: "official_info___nl"
  }, {
    path: "/nl/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___nl"
  }, {
    path: "/nl/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___nl"
  }, {
    path: "/nl/privacy",
    component: _3c47ff80,
    name: "privacy___nl"
  }, {
    path: "/nl/python",
    component: _1b500712,
    name: "python___nl"
  }, {
    path: "/nl/scratch",
    component: _121f51ef,
    name: "scratch___nl"
  }, {
    path: "/nl/sitemap",
    component: _1ea0f62a,
    name: "sitemap___nl"
  }, {
    path: "/nl/unity",
    component: _3d3ef42a,
    name: "unity___nl"
  }, {
    path: "/nl/videoblogging",
    component: _97b60f26,
    name: "videoblogging___nl"
  }, {
    path: "/nl/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___nl"
  }, {
    path: "/pl/coding",
    component: _bc12f270,
    name: "coding___pl"
  }, {
    path: "/pl/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___pl"
  }, {
    path: "/pl/contacts",
    component: _43c6e3ae,
    name: "contacts___pl"
  }, {
    path: "/pl/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___pl"
  }, {
    path: "/pl/frontend",
    component: _aea9cc26,
    name: "frontend___pl"
  }, {
    path: "/pl/game-design",
    component: _0e29fcee,
    name: "game-design___pl"
  }, {
    path: "/pl/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___pl"
  }, {
    path: "/pl/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___pl"
  }, {
    path: "/pl/mailing",
    component: _8d701d80,
    name: "mailing___pl"
  }, {
    path: "/pl/math",
    component: _00e271a6,
    name: "math___pl"
  }, {
    path: "/pl/official_info",
    component: _7031dffa,
    name: "official_info___pl"
  }, {
    path: "/pl/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___pl"
  }, {
    path: "/pl/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___pl"
  }, {
    path: "/pl/privacy",
    component: _3c47ff80,
    name: "privacy___pl"
  }, {
    path: "/pl/python",
    component: _1b500712,
    name: "python___pl"
  }, {
    path: "/pl/scratch",
    component: _121f51ef,
    name: "scratch___pl"
  }, {
    path: "/pl/sitemap",
    component: _1ea0f62a,
    name: "sitemap___pl"
  }, {
    path: "/pl/unity",
    component: _3d3ef42a,
    name: "unity___pl"
  }, {
    path: "/pl/videoblogging",
    component: _97b60f26,
    name: "videoblogging___pl"
  }, {
    path: "/pl/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___pl"
  }, {
    path: "/pt/coding",
    component: _bc12f270,
    name: "coding___pt"
  }, {
    path: "/pt/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___pt"
  }, {
    path: "/pt/contacts",
    component: _43c6e3ae,
    name: "contacts___pt"
  }, {
    path: "/pt/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___pt"
  }, {
    path: "/pt/frontend",
    component: _aea9cc26,
    name: "frontend___pt"
  }, {
    path: "/pt/game-design",
    component: _0e29fcee,
    name: "game-design___pt"
  }, {
    path: "/pt/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___pt"
  }, {
    path: "/pt/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___pt"
  }, {
    path: "/pt/mailing",
    component: _8d701d80,
    name: "mailing___pt"
  }, {
    path: "/pt/math",
    component: _00e271a6,
    name: "math___pt"
  }, {
    path: "/pt/official_info",
    component: _7031dffa,
    name: "official_info___pt"
  }, {
    path: "/pt/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___pt"
  }, {
    path: "/pt/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___pt"
  }, {
    path: "/pt/privacy",
    component: _3c47ff80,
    name: "privacy___pt"
  }, {
    path: "/pt/python",
    component: _1b500712,
    name: "python___pt"
  }, {
    path: "/pt/scratch",
    component: _121f51ef,
    name: "scratch___pt"
  }, {
    path: "/pt/sitemap",
    component: _1ea0f62a,
    name: "sitemap___pt"
  }, {
    path: "/pt/unity",
    component: _3d3ef42a,
    name: "unity___pt"
  }, {
    path: "/pt/videoblogging",
    component: _97b60f26,
    name: "videoblogging___pt"
  }, {
    path: "/pt/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___pt"
  }, {
    path: "/ro/coding",
    component: _bc12f270,
    name: "coding___ro"
  }, {
    path: "/ro/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___ro"
  }, {
    path: "/ro/contacts",
    component: _43c6e3ae,
    name: "contacts___ro"
  }, {
    path: "/ro/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___ro"
  }, {
    path: "/ro/frontend",
    component: _aea9cc26,
    name: "frontend___ro"
  }, {
    path: "/ro/game-design",
    component: _0e29fcee,
    name: "game-design___ro"
  }, {
    path: "/ro/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___ro"
  }, {
    path: "/ro/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___ro"
  }, {
    path: "/ro/mailing",
    component: _8d701d80,
    name: "mailing___ro"
  }, {
    path: "/ro/math",
    component: _00e271a6,
    name: "math___ro"
  }, {
    path: "/ro/official_info",
    component: _7031dffa,
    name: "official_info___ro"
  }, {
    path: "/ro/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___ro"
  }, {
    path: "/ro/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___ro"
  }, {
    path: "/ro/privacy",
    component: _3c47ff80,
    name: "privacy___ro"
  }, {
    path: "/ro/python",
    component: _1b500712,
    name: "python___ro"
  }, {
    path: "/ro/scratch",
    component: _121f51ef,
    name: "scratch___ro"
  }, {
    path: "/ro/sitemap",
    component: _1ea0f62a,
    name: "sitemap___ro"
  }, {
    path: "/ro/unity",
    component: _3d3ef42a,
    name: "unity___ro"
  }, {
    path: "/ro/videoblogging",
    component: _97b60f26,
    name: "videoblogging___ro"
  }, {
    path: "/ro/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___ro"
  }, {
    path: "/ru/coding",
    component: _bc12f270,
    name: "coding___ru"
  }, {
    path: "/ru/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___ru"
  }, {
    path: "/ru/contacts",
    component: _43c6e3ae,
    name: "contacts___ru"
  }, {
    path: "/ru/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___ru"
  }, {
    path: "/ru/frontend",
    component: _aea9cc26,
    name: "frontend___ru"
  }, {
    path: "/ru/game-design",
    component: _0e29fcee,
    name: "game-design___ru"
  }, {
    path: "/ru/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___ru"
  }, {
    path: "/ru/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___ru"
  }, {
    path: "/ru/mailing",
    component: _8d701d80,
    name: "mailing___ru"
  }, {
    path: "/ru/math",
    component: _00e271a6,
    name: "math___ru"
  }, {
    path: "/ru/official_info",
    component: _7031dffa,
    name: "official_info___ru"
  }, {
    path: "/ru/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___ru"
  }, {
    path: "/ru/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___ru"
  }, {
    path: "/ru/privacy",
    component: _3c47ff80,
    name: "privacy___ru"
  }, {
    path: "/ru/python",
    component: _1b500712,
    name: "python___ru"
  }, {
    path: "/ru/scratch",
    component: _121f51ef,
    name: "scratch___ru"
  }, {
    path: "/ru/sitemap",
    component: _1ea0f62a,
    name: "sitemap___ru"
  }, {
    path: "/ru/unity",
    component: _3d3ef42a,
    name: "unity___ru"
  }, {
    path: "/ru/videoblogging",
    component: _97b60f26,
    name: "videoblogging___ru"
  }, {
    path: "/ru/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___ru"
  }, {
    path: "/sk/coding",
    component: _bc12f270,
    name: "coding___sk"
  }, {
    path: "/sk/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___sk"
  }, {
    path: "/sk/contacts",
    component: _43c6e3ae,
    name: "contacts___sk"
  }, {
    path: "/sk/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___sk"
  }, {
    path: "/sk/frontend",
    component: _aea9cc26,
    name: "frontend___sk"
  }, {
    path: "/sk/game-design",
    component: _0e29fcee,
    name: "game-design___sk"
  }, {
    path: "/sk/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___sk"
  }, {
    path: "/sk/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___sk"
  }, {
    path: "/sk/mailing",
    component: _8d701d80,
    name: "mailing___sk"
  }, {
    path: "/sk/math",
    component: _00e271a6,
    name: "math___sk"
  }, {
    path: "/sk/official_info",
    component: _7031dffa,
    name: "official_info___sk"
  }, {
    path: "/sk/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___sk"
  }, {
    path: "/sk/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___sk"
  }, {
    path: "/sk/privacy",
    component: _3c47ff80,
    name: "privacy___sk"
  }, {
    path: "/sk/python",
    component: _1b500712,
    name: "python___sk"
  }, {
    path: "/sk/scratch",
    component: _121f51ef,
    name: "scratch___sk"
  }, {
    path: "/sk/sitemap",
    component: _1ea0f62a,
    name: "sitemap___sk"
  }, {
    path: "/sk/unity",
    component: _3d3ef42a,
    name: "unity___sk"
  }, {
    path: "/sk/videoblogging",
    component: _97b60f26,
    name: "videoblogging___sk"
  }, {
    path: "/sk/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___sk"
  }, {
    path: "/sq/coding",
    component: _bc12f270,
    name: "coding___sq"
  }, {
    path: "/sq/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___sq"
  }, {
    path: "/sq/contacts",
    component: _43c6e3ae,
    name: "contacts___sq"
  }, {
    path: "/sq/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___sq"
  }, {
    path: "/sq/frontend",
    component: _aea9cc26,
    name: "frontend___sq"
  }, {
    path: "/sq/game-design",
    component: _0e29fcee,
    name: "game-design___sq"
  }, {
    path: "/sq/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___sq"
  }, {
    path: "/sq/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___sq"
  }, {
    path: "/sq/mailing",
    component: _8d701d80,
    name: "mailing___sq"
  }, {
    path: "/sq/math",
    component: _00e271a6,
    name: "math___sq"
  }, {
    path: "/sq/official_info",
    component: _7031dffa,
    name: "official_info___sq"
  }, {
    path: "/sq/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___sq"
  }, {
    path: "/sq/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___sq"
  }, {
    path: "/sq/privacy",
    component: _3c47ff80,
    name: "privacy___sq"
  }, {
    path: "/sq/python",
    component: _1b500712,
    name: "python___sq"
  }, {
    path: "/sq/scratch",
    component: _121f51ef,
    name: "scratch___sq"
  }, {
    path: "/sq/sitemap",
    component: _1ea0f62a,
    name: "sitemap___sq"
  }, {
    path: "/sq/unity",
    component: _3d3ef42a,
    name: "unity___sq"
  }, {
    path: "/sq/videoblogging",
    component: _97b60f26,
    name: "videoblogging___sq"
  }, {
    path: "/sq/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___sq"
  }, {
    path: "/sr/coding",
    component: _bc12f270,
    name: "coding___sr"
  }, {
    path: "/sr/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___sr"
  }, {
    path: "/sr/contacts",
    component: _43c6e3ae,
    name: "contacts___sr"
  }, {
    path: "/sr/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___sr"
  }, {
    path: "/sr/frontend",
    component: _aea9cc26,
    name: "frontend___sr"
  }, {
    path: "/sr/game-design",
    component: _0e29fcee,
    name: "game-design___sr"
  }, {
    path: "/sr/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___sr"
  }, {
    path: "/sr/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___sr"
  }, {
    path: "/sr/mailing",
    component: _8d701d80,
    name: "mailing___sr"
  }, {
    path: "/sr/math",
    component: _00e271a6,
    name: "math___sr"
  }, {
    path: "/sr/official_info",
    component: _7031dffa,
    name: "official_info___sr"
  }, {
    path: "/sr/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___sr"
  }, {
    path: "/sr/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___sr"
  }, {
    path: "/sr/privacy",
    component: _3c47ff80,
    name: "privacy___sr"
  }, {
    path: "/sr/python",
    component: _1b500712,
    name: "python___sr"
  }, {
    path: "/sr/scratch",
    component: _121f51ef,
    name: "scratch___sr"
  }, {
    path: "/sr/sitemap",
    component: _1ea0f62a,
    name: "sitemap___sr"
  }, {
    path: "/sr/unity",
    component: _3d3ef42a,
    name: "unity___sr"
  }, {
    path: "/sr/videoblogging",
    component: _97b60f26,
    name: "videoblogging___sr"
  }, {
    path: "/sr/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___sr"
  }, {
    path: "/tg/coding",
    component: _bc12f270,
    name: "coding___tg"
  }, {
    path: "/tg/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___tg"
  }, {
    path: "/tg/contacts",
    component: _43c6e3ae,
    name: "contacts___tg"
  }, {
    path: "/tg/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___tg"
  }, {
    path: "/tg/frontend",
    component: _aea9cc26,
    name: "frontend___tg"
  }, {
    path: "/tg/game-design",
    component: _0e29fcee,
    name: "game-design___tg"
  }, {
    path: "/tg/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___tg"
  }, {
    path: "/tg/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___tg"
  }, {
    path: "/tg/mailing",
    component: _8d701d80,
    name: "mailing___tg"
  }, {
    path: "/tg/math",
    component: _00e271a6,
    name: "math___tg"
  }, {
    path: "/tg/official_info",
    component: _7031dffa,
    name: "official_info___tg"
  }, {
    path: "/tg/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___tg"
  }, {
    path: "/tg/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___tg"
  }, {
    path: "/tg/privacy",
    component: _3c47ff80,
    name: "privacy___tg"
  }, {
    path: "/tg/python",
    component: _1b500712,
    name: "python___tg"
  }, {
    path: "/tg/scratch",
    component: _121f51ef,
    name: "scratch___tg"
  }, {
    path: "/tg/sitemap",
    component: _1ea0f62a,
    name: "sitemap___tg"
  }, {
    path: "/tg/unity",
    component: _3d3ef42a,
    name: "unity___tg"
  }, {
    path: "/tg/videoblogging",
    component: _97b60f26,
    name: "videoblogging___tg"
  }, {
    path: "/tg/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___tg"
  }, {
    path: "/th/coding",
    component: _bc12f270,
    name: "coding___th"
  }, {
    path: "/th/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___th"
  }, {
    path: "/th/contacts",
    component: _43c6e3ae,
    name: "contacts___th"
  }, {
    path: "/th/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___th"
  }, {
    path: "/th/frontend",
    component: _aea9cc26,
    name: "frontend___th"
  }, {
    path: "/th/game-design",
    component: _0e29fcee,
    name: "game-design___th"
  }, {
    path: "/th/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___th"
  }, {
    path: "/th/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___th"
  }, {
    path: "/th/mailing",
    component: _8d701d80,
    name: "mailing___th"
  }, {
    path: "/th/math",
    component: _00e271a6,
    name: "math___th"
  }, {
    path: "/th/official_info",
    component: _7031dffa,
    name: "official_info___th"
  }, {
    path: "/th/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___th"
  }, {
    path: "/th/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___th"
  }, {
    path: "/th/privacy",
    component: _3c47ff80,
    name: "privacy___th"
  }, {
    path: "/th/python",
    component: _1b500712,
    name: "python___th"
  }, {
    path: "/th/scratch",
    component: _121f51ef,
    name: "scratch___th"
  }, {
    path: "/th/sitemap",
    component: _1ea0f62a,
    name: "sitemap___th"
  }, {
    path: "/th/unity",
    component: _3d3ef42a,
    name: "unity___th"
  }, {
    path: "/th/videoblogging",
    component: _97b60f26,
    name: "videoblogging___th"
  }, {
    path: "/th/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___th"
  }, {
    path: "/tr/coding",
    component: _bc12f270,
    name: "coding___tr"
  }, {
    path: "/tr/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___tr"
  }, {
    path: "/tr/contacts",
    component: _43c6e3ae,
    name: "contacts___tr"
  }, {
    path: "/tr/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___tr"
  }, {
    path: "/tr/frontend",
    component: _aea9cc26,
    name: "frontend___tr"
  }, {
    path: "/tr/game-design",
    component: _0e29fcee,
    name: "game-design___tr"
  }, {
    path: "/tr/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___tr"
  }, {
    path: "/tr/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___tr"
  }, {
    path: "/tr/mailing",
    component: _8d701d80,
    name: "mailing___tr"
  }, {
    path: "/tr/math",
    component: _00e271a6,
    name: "math___tr"
  }, {
    path: "/tr/official_info",
    component: _7031dffa,
    name: "official_info___tr"
  }, {
    path: "/tr/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___tr"
  }, {
    path: "/tr/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___tr"
  }, {
    path: "/tr/privacy",
    component: _3c47ff80,
    name: "privacy___tr"
  }, {
    path: "/tr/python",
    component: _1b500712,
    name: "python___tr"
  }, {
    path: "/tr/scratch",
    component: _121f51ef,
    name: "scratch___tr"
  }, {
    path: "/tr/sitemap",
    component: _1ea0f62a,
    name: "sitemap___tr"
  }, {
    path: "/tr/unity",
    component: _3d3ef42a,
    name: "unity___tr"
  }, {
    path: "/tr/videoblogging",
    component: _97b60f26,
    name: "videoblogging___tr"
  }, {
    path: "/tr/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___tr"
  }, {
    path: "/tt/coding",
    component: _bc12f270,
    name: "coding___tt"
  }, {
    path: "/tt/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___tt"
  }, {
    path: "/tt/contacts",
    component: _43c6e3ae,
    name: "contacts___tt"
  }, {
    path: "/tt/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___tt"
  }, {
    path: "/tt/frontend",
    component: _aea9cc26,
    name: "frontend___tt"
  }, {
    path: "/tt/game-design",
    component: _0e29fcee,
    name: "game-design___tt"
  }, {
    path: "/tt/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___tt"
  }, {
    path: "/tt/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___tt"
  }, {
    path: "/tt/mailing",
    component: _8d701d80,
    name: "mailing___tt"
  }, {
    path: "/tt/math",
    component: _00e271a6,
    name: "math___tt"
  }, {
    path: "/tt/official_info",
    component: _7031dffa,
    name: "official_info___tt"
  }, {
    path: "/tt/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___tt"
  }, {
    path: "/tt/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___tt"
  }, {
    path: "/tt/privacy",
    component: _3c47ff80,
    name: "privacy___tt"
  }, {
    path: "/tt/python",
    component: _1b500712,
    name: "python___tt"
  }, {
    path: "/tt/scratch",
    component: _121f51ef,
    name: "scratch___tt"
  }, {
    path: "/tt/sitemap",
    component: _1ea0f62a,
    name: "sitemap___tt"
  }, {
    path: "/tt/unity",
    component: _3d3ef42a,
    name: "unity___tt"
  }, {
    path: "/tt/videoblogging",
    component: _97b60f26,
    name: "videoblogging___tt"
  }, {
    path: "/tt/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___tt"
  }, {
    path: "/uk/coding",
    component: _bc12f270,
    name: "coding___uk"
  }, {
    path: "/uk/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___uk"
  }, {
    path: "/uk/contacts",
    component: _43c6e3ae,
    name: "contacts___uk"
  }, {
    path: "/uk/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___uk"
  }, {
    path: "/uk/frontend",
    component: _aea9cc26,
    name: "frontend___uk"
  }, {
    path: "/uk/game-design",
    component: _0e29fcee,
    name: "game-design___uk"
  }, {
    path: "/uk/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___uk"
  }, {
    path: "/uk/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___uk"
  }, {
    path: "/uk/mailing",
    component: _8d701d80,
    name: "mailing___uk"
  }, {
    path: "/uk/math",
    component: _00e271a6,
    name: "math___uk"
  }, {
    path: "/uk/official_info",
    component: _7031dffa,
    name: "official_info___uk"
  }, {
    path: "/uk/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___uk"
  }, {
    path: "/uk/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___uk"
  }, {
    path: "/uk/privacy",
    component: _3c47ff80,
    name: "privacy___uk"
  }, {
    path: "/uk/python",
    component: _1b500712,
    name: "python___uk"
  }, {
    path: "/uk/scratch",
    component: _121f51ef,
    name: "scratch___uk"
  }, {
    path: "/uk/sitemap",
    component: _1ea0f62a,
    name: "sitemap___uk"
  }, {
    path: "/uk/unity",
    component: _3d3ef42a,
    name: "unity___uk"
  }, {
    path: "/uk/videoblogging",
    component: _97b60f26,
    name: "videoblogging___uk"
  }, {
    path: "/uk/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___uk"
  }, {
    path: "/vi/coding",
    component: _bc12f270,
    name: "coding___vi"
  }, {
    path: "/vi/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___vi"
  }, {
    path: "/vi/contacts",
    component: _43c6e3ae,
    name: "contacts___vi"
  }, {
    path: "/vi/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___vi"
  }, {
    path: "/vi/frontend",
    component: _aea9cc26,
    name: "frontend___vi"
  }, {
    path: "/vi/game-design",
    component: _0e29fcee,
    name: "game-design___vi"
  }, {
    path: "/vi/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___vi"
  }, {
    path: "/vi/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___vi"
  }, {
    path: "/vi/mailing",
    component: _8d701d80,
    name: "mailing___vi"
  }, {
    path: "/vi/math",
    component: _00e271a6,
    name: "math___vi"
  }, {
    path: "/vi/official_info",
    component: _7031dffa,
    name: "official_info___vi"
  }, {
    path: "/vi/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___vi"
  }, {
    path: "/vi/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___vi"
  }, {
    path: "/vi/privacy",
    component: _3c47ff80,
    name: "privacy___vi"
  }, {
    path: "/vi/python",
    component: _1b500712,
    name: "python___vi"
  }, {
    path: "/vi/scratch",
    component: _121f51ef,
    name: "scratch___vi"
  }, {
    path: "/vi/sitemap",
    component: _1ea0f62a,
    name: "sitemap___vi"
  }, {
    path: "/vi/unity",
    component: _3d3ef42a,
    name: "unity___vi"
  }, {
    path: "/vi/videoblogging",
    component: _97b60f26,
    name: "videoblogging___vi"
  }, {
    path: "/vi/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___vi"
  }, {
    path: "/zh/coding",
    component: _bc12f270,
    name: "coding___zh"
  }, {
    path: "/zh/computernaya-gramotnost",
    component: _1969072c,
    name: "computernaya-gramotnost___zh"
  }, {
    path: "/zh/contacts",
    component: _43c6e3ae,
    name: "contacts___zh"
  }, {
    path: "/zh/ege-informatika",
    component: _542bbf5e,
    name: "ege-informatika___zh"
  }, {
    path: "/zh/frontend",
    component: _aea9cc26,
    name: "frontend___zh"
  }, {
    path: "/zh/game-design",
    component: _0e29fcee,
    name: "game-design___zh"
  }, {
    path: "/zh/graphic-design",
    component: _0e3af15e,
    name: "graphic-design___zh"
  }, {
    path: "/zh/healthcheck",
    component: _cc0a88be,
    name: "healthcheck___zh"
  }, {
    path: "/zh/mailing",
    component: _8d701d80,
    name: "mailing___zh"
  }, {
    path: "/zh/math",
    component: _00e271a6,
    name: "math___zh"
  }, {
    path: "/zh/official_info",
    component: _7031dffa,
    name: "official_info___zh"
  }, {
    path: "/zh/osnovy-programmirovaniya",
    component: _f897df7e,
    name: "osnovy-programmirovaniya___zh"
  }, {
    path: "/zh/predprinimatelstvo",
    component: _60de0a84,
    name: "predprinimatelstvo___zh"
  }, {
    path: "/zh/privacy",
    component: _3c47ff80,
    name: "privacy___zh"
  }, {
    path: "/zh/python",
    component: _1b500712,
    name: "python___zh"
  }, {
    path: "/zh/scratch",
    component: _121f51ef,
    name: "scratch___zh"
  }, {
    path: "/zh/sitemap",
    component: _1ea0f62a,
    name: "sitemap___zh"
  }, {
    path: "/zh/unity",
    component: _3d3ef42a,
    name: "unity___zh"
  }, {
    path: "/zh/videoblogging",
    component: _97b60f26,
    name: "videoblogging___zh"
  }, {
    path: "/zh/web-razrabotka",
    component: _40480a92,
    name: "web-razrabotka___zh"
  }, {
    path: "/af/coding/camp",
    component: _30e19abe,
    name: "coding-camp___af"
  }, {
    path: "/af/coding/creative",
    component: _09806d6c,
    name: "coding-creative___af"
  }, {
    path: "/af/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___af"
  }, {
    path: "/af/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___af"
  }, {
    path: "/af/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___af"
  }, {
    path: "/af/coding/junior",
    component: _4468f334,
    name: "coding-junior___af"
  }, {
    path: "/af/coding/pc",
    component: _5a493830,
    name: "coding-pc___af"
  }, {
    path: "/af/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___af"
  }, {
    path: "/af/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___af"
  }, {
    path: "/af/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___af"
  }, {
    path: "/af/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___af"
  }, {
    path: "/af/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___af"
  }, {
    path: "/af/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___af"
  }, {
    path: "/af/payment/register",
    component: _6339df32,
    name: "payment-register___af"
  }, {
    path: "/af/payment/status",
    component: _7443ad36,
    name: "payment-status___af"
  }, {
    path: "/af/payment/success",
    component: _00583aef,
    name: "payment-success___af"
  }, {
    path: "/ar/coding/camp",
    component: _30e19abe,
    name: "coding-camp___ar"
  }, {
    path: "/ar/coding/creative",
    component: _09806d6c,
    name: "coding-creative___ar"
  }, {
    path: "/ar/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___ar"
  }, {
    path: "/ar/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___ar"
  }, {
    path: "/ar/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___ar"
  }, {
    path: "/ar/coding/junior",
    component: _4468f334,
    name: "coding-junior___ar"
  }, {
    path: "/ar/coding/pc",
    component: _5a493830,
    name: "coding-pc___ar"
  }, {
    path: "/ar/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___ar"
  }, {
    path: "/ar/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___ar"
  }, {
    path: "/ar/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___ar"
  }, {
    path: "/ar/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___ar"
  }, {
    path: "/ar/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___ar"
  }, {
    path: "/ar/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___ar"
  }, {
    path: "/ar/payment/register",
    component: _6339df32,
    name: "payment-register___ar"
  }, {
    path: "/ar/payment/status",
    component: _7443ad36,
    name: "payment-status___ar"
  }, {
    path: "/ar/payment/success",
    component: _00583aef,
    name: "payment-success___ar"
  }, {
    path: "/az/coding/camp",
    component: _30e19abe,
    name: "coding-camp___az"
  }, {
    path: "/az/coding/creative",
    component: _09806d6c,
    name: "coding-creative___az"
  }, {
    path: "/az/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___az"
  }, {
    path: "/az/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___az"
  }, {
    path: "/az/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___az"
  }, {
    path: "/az/coding/junior",
    component: _4468f334,
    name: "coding-junior___az"
  }, {
    path: "/az/coding/pc",
    component: _5a493830,
    name: "coding-pc___az"
  }, {
    path: "/az/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___az"
  }, {
    path: "/az/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___az"
  }, {
    path: "/az/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___az"
  }, {
    path: "/az/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___az"
  }, {
    path: "/az/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___az"
  }, {
    path: "/az/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___az"
  }, {
    path: "/az/payment/register",
    component: _6339df32,
    name: "payment-register___az"
  }, {
    path: "/az/payment/status",
    component: _7443ad36,
    name: "payment-status___az"
  }, {
    path: "/az/payment/success",
    component: _00583aef,
    name: "payment-success___az"
  }, {
    path: "/bg/coding/camp",
    component: _30e19abe,
    name: "coding-camp___bg"
  }, {
    path: "/bg/coding/creative",
    component: _09806d6c,
    name: "coding-creative___bg"
  }, {
    path: "/bg/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___bg"
  }, {
    path: "/bg/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___bg"
  }, {
    path: "/bg/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___bg"
  }, {
    path: "/bg/coding/junior",
    component: _4468f334,
    name: "coding-junior___bg"
  }, {
    path: "/bg/coding/pc",
    component: _5a493830,
    name: "coding-pc___bg"
  }, {
    path: "/bg/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___bg"
  }, {
    path: "/bg/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___bg"
  }, {
    path: "/bg/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___bg"
  }, {
    path: "/bg/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___bg"
  }, {
    path: "/bg/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___bg"
  }, {
    path: "/bg/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___bg"
  }, {
    path: "/bg/payment/register",
    component: _6339df32,
    name: "payment-register___bg"
  }, {
    path: "/bg/payment/status",
    component: _7443ad36,
    name: "payment-status___bg"
  }, {
    path: "/bg/payment/success",
    component: _00583aef,
    name: "payment-success___bg"
  }, {
    path: "/bs/coding/camp",
    component: _30e19abe,
    name: "coding-camp___bs"
  }, {
    path: "/bs/coding/creative",
    component: _09806d6c,
    name: "coding-creative___bs"
  }, {
    path: "/bs/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___bs"
  }, {
    path: "/bs/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___bs"
  }, {
    path: "/bs/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___bs"
  }, {
    path: "/bs/coding/junior",
    component: _4468f334,
    name: "coding-junior___bs"
  }, {
    path: "/bs/coding/pc",
    component: _5a493830,
    name: "coding-pc___bs"
  }, {
    path: "/bs/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___bs"
  }, {
    path: "/bs/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___bs"
  }, {
    path: "/bs/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___bs"
  }, {
    path: "/bs/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___bs"
  }, {
    path: "/bs/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___bs"
  }, {
    path: "/bs/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___bs"
  }, {
    path: "/bs/payment/register",
    component: _6339df32,
    name: "payment-register___bs"
  }, {
    path: "/bs/payment/status",
    component: _7443ad36,
    name: "payment-status___bs"
  }, {
    path: "/bs/payment/success",
    component: _00583aef,
    name: "payment-success___bs"
  }, {
    path: "/ca/coding/camp",
    component: _30e19abe,
    name: "coding-camp___ca"
  }, {
    path: "/ca/coding/creative",
    component: _09806d6c,
    name: "coding-creative___ca"
  }, {
    path: "/ca/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___ca"
  }, {
    path: "/ca/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___ca"
  }, {
    path: "/ca/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___ca"
  }, {
    path: "/ca/coding/junior",
    component: _4468f334,
    name: "coding-junior___ca"
  }, {
    path: "/ca/coding/pc",
    component: _5a493830,
    name: "coding-pc___ca"
  }, {
    path: "/ca/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___ca"
  }, {
    path: "/ca/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___ca"
  }, {
    path: "/ca/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___ca"
  }, {
    path: "/ca/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___ca"
  }, {
    path: "/ca/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___ca"
  }, {
    path: "/ca/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___ca"
  }, {
    path: "/ca/payment/register",
    component: _6339df32,
    name: "payment-register___ca"
  }, {
    path: "/ca/payment/status",
    component: _7443ad36,
    name: "payment-status___ca"
  }, {
    path: "/ca/payment/success",
    component: _00583aef,
    name: "payment-success___ca"
  }, {
    path: "/cis/coding/camp",
    component: _30e19abe,
    name: "coding-camp___cis"
  }, {
    path: "/cis/coding/creative",
    component: _09806d6c,
    name: "coding-creative___cis"
  }, {
    path: "/cis/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___cis"
  }, {
    path: "/cis/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___cis"
  }, {
    path: "/cis/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___cis"
  }, {
    path: "/cis/coding/junior",
    component: _4468f334,
    name: "coding-junior___cis"
  }, {
    path: "/cis/coding/pc",
    component: _5a493830,
    name: "coding-pc___cis"
  }, {
    path: "/cis/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___cis"
  }, {
    path: "/cis/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___cis"
  }, {
    path: "/cis/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___cis"
  }, {
    path: "/cis/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___cis"
  }, {
    path: "/cis/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___cis"
  }, {
    path: "/cis/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___cis"
  }, {
    path: "/cis/payment/register",
    component: _6339df32,
    name: "payment-register___cis"
  }, {
    path: "/cis/payment/status",
    component: _7443ad36,
    name: "payment-status___cis"
  }, {
    path: "/cis/payment/success",
    component: _00583aef,
    name: "payment-success___cis"
  }, {
    path: "/cr/coding/camp",
    component: _30e19abe,
    name: "coding-camp___cr"
  }, {
    path: "/cr/coding/creative",
    component: _09806d6c,
    name: "coding-creative___cr"
  }, {
    path: "/cr/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___cr"
  }, {
    path: "/cr/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___cr"
  }, {
    path: "/cr/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___cr"
  }, {
    path: "/cr/coding/junior",
    component: _4468f334,
    name: "coding-junior___cr"
  }, {
    path: "/cr/coding/pc",
    component: _5a493830,
    name: "coding-pc___cr"
  }, {
    path: "/cr/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___cr"
  }, {
    path: "/cr/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___cr"
  }, {
    path: "/cr/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___cr"
  }, {
    path: "/cr/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___cr"
  }, {
    path: "/cr/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___cr"
  }, {
    path: "/cr/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___cr"
  }, {
    path: "/cr/payment/register",
    component: _6339df32,
    name: "payment-register___cr"
  }, {
    path: "/cr/payment/status",
    component: _7443ad36,
    name: "payment-status___cr"
  }, {
    path: "/cr/payment/success",
    component: _00583aef,
    name: "payment-success___cr"
  }, {
    path: "/cs/coding/camp",
    component: _30e19abe,
    name: "coding-camp___cs"
  }, {
    path: "/cs/coding/creative",
    component: _09806d6c,
    name: "coding-creative___cs"
  }, {
    path: "/cs/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___cs"
  }, {
    path: "/cs/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___cs"
  }, {
    path: "/cs/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___cs"
  }, {
    path: "/cs/coding/junior",
    component: _4468f334,
    name: "coding-junior___cs"
  }, {
    path: "/cs/coding/pc",
    component: _5a493830,
    name: "coding-pc___cs"
  }, {
    path: "/cs/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___cs"
  }, {
    path: "/cs/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___cs"
  }, {
    path: "/cs/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___cs"
  }, {
    path: "/cs/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___cs"
  }, {
    path: "/cs/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___cs"
  }, {
    path: "/cs/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___cs"
  }, {
    path: "/cs/payment/register",
    component: _6339df32,
    name: "payment-register___cs"
  }, {
    path: "/cs/payment/status",
    component: _7443ad36,
    name: "payment-status___cs"
  }, {
    path: "/cs/payment/success",
    component: _00583aef,
    name: "payment-success___cs"
  }, {
    path: "/da/coding/camp",
    component: _30e19abe,
    name: "coding-camp___da"
  }, {
    path: "/da/coding/creative",
    component: _09806d6c,
    name: "coding-creative___da"
  }, {
    path: "/da/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___da"
  }, {
    path: "/da/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___da"
  }, {
    path: "/da/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___da"
  }, {
    path: "/da/coding/junior",
    component: _4468f334,
    name: "coding-junior___da"
  }, {
    path: "/da/coding/pc",
    component: _5a493830,
    name: "coding-pc___da"
  }, {
    path: "/da/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___da"
  }, {
    path: "/da/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___da"
  }, {
    path: "/da/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___da"
  }, {
    path: "/da/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___da"
  }, {
    path: "/da/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___da"
  }, {
    path: "/da/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___da"
  }, {
    path: "/da/payment/register",
    component: _6339df32,
    name: "payment-register___da"
  }, {
    path: "/da/payment/status",
    component: _7443ad36,
    name: "payment-status___da"
  }, {
    path: "/da/payment/success",
    component: _00583aef,
    name: "payment-success___da"
  }, {
    path: "/de/coding/camp",
    component: _30e19abe,
    name: "coding-camp___de"
  }, {
    path: "/de/coding/creative",
    component: _09806d6c,
    name: "coding-creative___de"
  }, {
    path: "/de/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___de"
  }, {
    path: "/de/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___de"
  }, {
    path: "/de/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___de"
  }, {
    path: "/de/coding/junior",
    component: _4468f334,
    name: "coding-junior___de"
  }, {
    path: "/de/coding/pc",
    component: _5a493830,
    name: "coding-pc___de"
  }, {
    path: "/de/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___de"
  }, {
    path: "/de/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___de"
  }, {
    path: "/de/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___de"
  }, {
    path: "/de/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___de"
  }, {
    path: "/de/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___de"
  }, {
    path: "/de/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___de"
  }, {
    path: "/de/payment/register",
    component: _6339df32,
    name: "payment-register___de"
  }, {
    path: "/de/payment/status",
    component: _7443ad36,
    name: "payment-status___de"
  }, {
    path: "/de/payment/success",
    component: _00583aef,
    name: "payment-success___de"
  }, {
    path: "/el/coding/camp",
    component: _30e19abe,
    name: "coding-camp___el"
  }, {
    path: "/el/coding/creative",
    component: _09806d6c,
    name: "coding-creative___el"
  }, {
    path: "/el/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___el"
  }, {
    path: "/el/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___el"
  }, {
    path: "/el/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___el"
  }, {
    path: "/el/coding/junior",
    component: _4468f334,
    name: "coding-junior___el"
  }, {
    path: "/el/coding/pc",
    component: _5a493830,
    name: "coding-pc___el"
  }, {
    path: "/el/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___el"
  }, {
    path: "/el/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___el"
  }, {
    path: "/el/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___el"
  }, {
    path: "/el/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___el"
  }, {
    path: "/el/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___el"
  }, {
    path: "/el/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___el"
  }, {
    path: "/el/payment/register",
    component: _6339df32,
    name: "payment-register___el"
  }, {
    path: "/el/payment/status",
    component: _7443ad36,
    name: "payment-status___el"
  }, {
    path: "/el/payment/success",
    component: _00583aef,
    name: "payment-success___el"
  }, {
    path: "/en/coding/camp",
    component: _30e19abe,
    name: "coding-camp___en"
  }, {
    path: "/en/coding/creative",
    component: _09806d6c,
    name: "coding-creative___en"
  }, {
    path: "/en/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___en"
  }, {
    path: "/en/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___en"
  }, {
    path: "/en/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___en"
  }, {
    path: "/en/coding/junior",
    component: _4468f334,
    name: "coding-junior___en"
  }, {
    path: "/en/coding/pc",
    component: _5a493830,
    name: "coding-pc___en"
  }, {
    path: "/en/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___en"
  }, {
    path: "/en/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___en"
  }, {
    path: "/en/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___en"
  }, {
    path: "/en/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___en"
  }, {
    path: "/en/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___en"
  }, {
    path: "/en/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___en"
  }, {
    path: "/en/payment/register",
    component: _6339df32,
    name: "payment-register___en"
  }, {
    path: "/en/payment/status",
    component: _7443ad36,
    name: "payment-status___en"
  }, {
    path: "/en/payment/success",
    component: _00583aef,
    name: "payment-success___en"
  }, {
    path: "/es/coding/camp",
    component: _30e19abe,
    name: "coding-camp___es"
  }, {
    path: "/es/coding/creative",
    component: _09806d6c,
    name: "coding-creative___es"
  }, {
    path: "/es/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___es"
  }, {
    path: "/es/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___es"
  }, {
    path: "/es/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___es"
  }, {
    path: "/es/coding/junior",
    component: _4468f334,
    name: "coding-junior___es"
  }, {
    path: "/es/coding/pc",
    component: _5a493830,
    name: "coding-pc___es"
  }, {
    path: "/es/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___es"
  }, {
    path: "/es/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___es"
  }, {
    path: "/es/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___es"
  }, {
    path: "/es/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___es"
  }, {
    path: "/es/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___es"
  }, {
    path: "/es/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___es"
  }, {
    path: "/es/payment/register",
    component: _6339df32,
    name: "payment-register___es"
  }, {
    path: "/es/payment/status",
    component: _7443ad36,
    name: "payment-status___es"
  }, {
    path: "/es/payment/success",
    component: _00583aef,
    name: "payment-success___es"
  }, {
    path: "/et/coding/camp",
    component: _30e19abe,
    name: "coding-camp___et"
  }, {
    path: "/et/coding/creative",
    component: _09806d6c,
    name: "coding-creative___et"
  }, {
    path: "/et/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___et"
  }, {
    path: "/et/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___et"
  }, {
    path: "/et/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___et"
  }, {
    path: "/et/coding/junior",
    component: _4468f334,
    name: "coding-junior___et"
  }, {
    path: "/et/coding/pc",
    component: _5a493830,
    name: "coding-pc___et"
  }, {
    path: "/et/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___et"
  }, {
    path: "/et/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___et"
  }, {
    path: "/et/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___et"
  }, {
    path: "/et/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___et"
  }, {
    path: "/et/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___et"
  }, {
    path: "/et/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___et"
  }, {
    path: "/et/payment/register",
    component: _6339df32,
    name: "payment-register___et"
  }, {
    path: "/et/payment/status",
    component: _7443ad36,
    name: "payment-status___et"
  }, {
    path: "/et/payment/success",
    component: _00583aef,
    name: "payment-success___et"
  }, {
    path: "/fr/coding/camp",
    component: _30e19abe,
    name: "coding-camp___fr"
  }, {
    path: "/fr/coding/creative",
    component: _09806d6c,
    name: "coding-creative___fr"
  }, {
    path: "/fr/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___fr"
  }, {
    path: "/fr/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___fr"
  }, {
    path: "/fr/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___fr"
  }, {
    path: "/fr/coding/junior",
    component: _4468f334,
    name: "coding-junior___fr"
  }, {
    path: "/fr/coding/pc",
    component: _5a493830,
    name: "coding-pc___fr"
  }, {
    path: "/fr/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___fr"
  }, {
    path: "/fr/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___fr"
  }, {
    path: "/fr/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___fr"
  }, {
    path: "/fr/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___fr"
  }, {
    path: "/fr/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___fr"
  }, {
    path: "/fr/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___fr"
  }, {
    path: "/fr/payment/register",
    component: _6339df32,
    name: "payment-register___fr"
  }, {
    path: "/fr/payment/status",
    component: _7443ad36,
    name: "payment-status___fr"
  }, {
    path: "/fr/payment/success",
    component: _00583aef,
    name: "payment-success___fr"
  }, {
    path: "/he/coding/camp",
    component: _30e19abe,
    name: "coding-camp___he"
  }, {
    path: "/he/coding/creative",
    component: _09806d6c,
    name: "coding-creative___he"
  }, {
    path: "/he/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___he"
  }, {
    path: "/he/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___he"
  }, {
    path: "/he/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___he"
  }, {
    path: "/he/coding/junior",
    component: _4468f334,
    name: "coding-junior___he"
  }, {
    path: "/he/coding/pc",
    component: _5a493830,
    name: "coding-pc___he"
  }, {
    path: "/he/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___he"
  }, {
    path: "/he/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___he"
  }, {
    path: "/he/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___he"
  }, {
    path: "/he/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___he"
  }, {
    path: "/he/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___he"
  }, {
    path: "/he/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___he"
  }, {
    path: "/he/payment/register",
    component: _6339df32,
    name: "payment-register___he"
  }, {
    path: "/he/payment/status",
    component: _7443ad36,
    name: "payment-status___he"
  }, {
    path: "/he/payment/success",
    component: _00583aef,
    name: "payment-success___he"
  }, {
    path: "/hr/coding/camp",
    component: _30e19abe,
    name: "coding-camp___hr"
  }, {
    path: "/hr/coding/creative",
    component: _09806d6c,
    name: "coding-creative___hr"
  }, {
    path: "/hr/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___hr"
  }, {
    path: "/hr/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___hr"
  }, {
    path: "/hr/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___hr"
  }, {
    path: "/hr/coding/junior",
    component: _4468f334,
    name: "coding-junior___hr"
  }, {
    path: "/hr/coding/pc",
    component: _5a493830,
    name: "coding-pc___hr"
  }, {
    path: "/hr/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___hr"
  }, {
    path: "/hr/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___hr"
  }, {
    path: "/hr/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___hr"
  }, {
    path: "/hr/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___hr"
  }, {
    path: "/hr/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___hr"
  }, {
    path: "/hr/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___hr"
  }, {
    path: "/hr/payment/register",
    component: _6339df32,
    name: "payment-register___hr"
  }, {
    path: "/hr/payment/status",
    component: _7443ad36,
    name: "payment-status___hr"
  }, {
    path: "/hr/payment/success",
    component: _00583aef,
    name: "payment-success___hr"
  }, {
    path: "/hu/coding/camp",
    component: _30e19abe,
    name: "coding-camp___hu"
  }, {
    path: "/hu/coding/creative",
    component: _09806d6c,
    name: "coding-creative___hu"
  }, {
    path: "/hu/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___hu"
  }, {
    path: "/hu/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___hu"
  }, {
    path: "/hu/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___hu"
  }, {
    path: "/hu/coding/junior",
    component: _4468f334,
    name: "coding-junior___hu"
  }, {
    path: "/hu/coding/pc",
    component: _5a493830,
    name: "coding-pc___hu"
  }, {
    path: "/hu/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___hu"
  }, {
    path: "/hu/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___hu"
  }, {
    path: "/hu/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___hu"
  }, {
    path: "/hu/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___hu"
  }, {
    path: "/hu/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___hu"
  }, {
    path: "/hu/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___hu"
  }, {
    path: "/hu/payment/register",
    component: _6339df32,
    name: "payment-register___hu"
  }, {
    path: "/hu/payment/status",
    component: _7443ad36,
    name: "payment-status___hu"
  }, {
    path: "/hu/payment/success",
    component: _00583aef,
    name: "payment-success___hu"
  }, {
    path: "/id/coding/camp",
    component: _30e19abe,
    name: "coding-camp___id"
  }, {
    path: "/id/coding/creative",
    component: _09806d6c,
    name: "coding-creative___id"
  }, {
    path: "/id/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___id"
  }, {
    path: "/id/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___id"
  }, {
    path: "/id/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___id"
  }, {
    path: "/id/coding/junior",
    component: _4468f334,
    name: "coding-junior___id"
  }, {
    path: "/id/coding/pc",
    component: _5a493830,
    name: "coding-pc___id"
  }, {
    path: "/id/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___id"
  }, {
    path: "/id/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___id"
  }, {
    path: "/id/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___id"
  }, {
    path: "/id/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___id"
  }, {
    path: "/id/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___id"
  }, {
    path: "/id/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___id"
  }, {
    path: "/id/payment/register",
    component: _6339df32,
    name: "payment-register___id"
  }, {
    path: "/id/payment/status",
    component: _7443ad36,
    name: "payment-status___id"
  }, {
    path: "/id/payment/success",
    component: _00583aef,
    name: "payment-success___id"
  }, {
    path: "/it/coding/camp",
    component: _30e19abe,
    name: "coding-camp___it"
  }, {
    path: "/it/coding/creative",
    component: _09806d6c,
    name: "coding-creative___it"
  }, {
    path: "/it/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___it"
  }, {
    path: "/it/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___it"
  }, {
    path: "/it/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___it"
  }, {
    path: "/it/coding/junior",
    component: _4468f334,
    name: "coding-junior___it"
  }, {
    path: "/it/coding/pc",
    component: _5a493830,
    name: "coding-pc___it"
  }, {
    path: "/it/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___it"
  }, {
    path: "/it/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___it"
  }, {
    path: "/it/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___it"
  }, {
    path: "/it/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___it"
  }, {
    path: "/it/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___it"
  }, {
    path: "/it/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___it"
  }, {
    path: "/it/payment/register",
    component: _6339df32,
    name: "payment-register___it"
  }, {
    path: "/it/payment/status",
    component: _7443ad36,
    name: "payment-status___it"
  }, {
    path: "/it/payment/success",
    component: _00583aef,
    name: "payment-success___it"
  }, {
    path: "/ka/coding/camp",
    component: _30e19abe,
    name: "coding-camp___ka"
  }, {
    path: "/ka/coding/creative",
    component: _09806d6c,
    name: "coding-creative___ka"
  }, {
    path: "/ka/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___ka"
  }, {
    path: "/ka/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___ka"
  }, {
    path: "/ka/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___ka"
  }, {
    path: "/ka/coding/junior",
    component: _4468f334,
    name: "coding-junior___ka"
  }, {
    path: "/ka/coding/pc",
    component: _5a493830,
    name: "coding-pc___ka"
  }, {
    path: "/ka/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___ka"
  }, {
    path: "/ka/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___ka"
  }, {
    path: "/ka/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___ka"
  }, {
    path: "/ka/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___ka"
  }, {
    path: "/ka/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___ka"
  }, {
    path: "/ka/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___ka"
  }, {
    path: "/ka/payment/register",
    component: _6339df32,
    name: "payment-register___ka"
  }, {
    path: "/ka/payment/status",
    component: _7443ad36,
    name: "payment-status___ka"
  }, {
    path: "/ka/payment/success",
    component: _00583aef,
    name: "payment-success___ka"
  }, {
    path: "/kh/coding/camp",
    component: _30e19abe,
    name: "coding-camp___kh"
  }, {
    path: "/kh/coding/creative",
    component: _09806d6c,
    name: "coding-creative___kh"
  }, {
    path: "/kh/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___kh"
  }, {
    path: "/kh/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___kh"
  }, {
    path: "/kh/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___kh"
  }, {
    path: "/kh/coding/junior",
    component: _4468f334,
    name: "coding-junior___kh"
  }, {
    path: "/kh/coding/pc",
    component: _5a493830,
    name: "coding-pc___kh"
  }, {
    path: "/kh/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___kh"
  }, {
    path: "/kh/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___kh"
  }, {
    path: "/kh/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___kh"
  }, {
    path: "/kh/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___kh"
  }, {
    path: "/kh/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___kh"
  }, {
    path: "/kh/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___kh"
  }, {
    path: "/kh/payment/register",
    component: _6339df32,
    name: "payment-register___kh"
  }, {
    path: "/kh/payment/status",
    component: _7443ad36,
    name: "payment-status___kh"
  }, {
    path: "/kh/payment/success",
    component: _00583aef,
    name: "payment-success___kh"
  }, {
    path: "/kk/coding/camp",
    component: _30e19abe,
    name: "coding-camp___kk"
  }, {
    path: "/kk/coding/creative",
    component: _09806d6c,
    name: "coding-creative___kk"
  }, {
    path: "/kk/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___kk"
  }, {
    path: "/kk/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___kk"
  }, {
    path: "/kk/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___kk"
  }, {
    path: "/kk/coding/junior",
    component: _4468f334,
    name: "coding-junior___kk"
  }, {
    path: "/kk/coding/pc",
    component: _5a493830,
    name: "coding-pc___kk"
  }, {
    path: "/kk/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___kk"
  }, {
    path: "/kk/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___kk"
  }, {
    path: "/kk/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___kk"
  }, {
    path: "/kk/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___kk"
  }, {
    path: "/kk/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___kk"
  }, {
    path: "/kk/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___kk"
  }, {
    path: "/kk/payment/register",
    component: _6339df32,
    name: "payment-register___kk"
  }, {
    path: "/kk/payment/status",
    component: _7443ad36,
    name: "payment-status___kk"
  }, {
    path: "/kk/payment/success",
    component: _00583aef,
    name: "payment-success___kk"
  }, {
    path: "/lt/coding/camp",
    component: _30e19abe,
    name: "coding-camp___lt"
  }, {
    path: "/lt/coding/creative",
    component: _09806d6c,
    name: "coding-creative___lt"
  }, {
    path: "/lt/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___lt"
  }, {
    path: "/lt/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___lt"
  }, {
    path: "/lt/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___lt"
  }, {
    path: "/lt/coding/junior",
    component: _4468f334,
    name: "coding-junior___lt"
  }, {
    path: "/lt/coding/pc",
    component: _5a493830,
    name: "coding-pc___lt"
  }, {
    path: "/lt/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___lt"
  }, {
    path: "/lt/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___lt"
  }, {
    path: "/lt/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___lt"
  }, {
    path: "/lt/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___lt"
  }, {
    path: "/lt/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___lt"
  }, {
    path: "/lt/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___lt"
  }, {
    path: "/lt/payment/register",
    component: _6339df32,
    name: "payment-register___lt"
  }, {
    path: "/lt/payment/status",
    component: _7443ad36,
    name: "payment-status___lt"
  }, {
    path: "/lt/payment/success",
    component: _00583aef,
    name: "payment-success___lt"
  }, {
    path: "/mk/coding/camp",
    component: _30e19abe,
    name: "coding-camp___mk"
  }, {
    path: "/mk/coding/creative",
    component: _09806d6c,
    name: "coding-creative___mk"
  }, {
    path: "/mk/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___mk"
  }, {
    path: "/mk/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___mk"
  }, {
    path: "/mk/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___mk"
  }, {
    path: "/mk/coding/junior",
    component: _4468f334,
    name: "coding-junior___mk"
  }, {
    path: "/mk/coding/pc",
    component: _5a493830,
    name: "coding-pc___mk"
  }, {
    path: "/mk/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___mk"
  }, {
    path: "/mk/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___mk"
  }, {
    path: "/mk/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___mk"
  }, {
    path: "/mk/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___mk"
  }, {
    path: "/mk/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___mk"
  }, {
    path: "/mk/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___mk"
  }, {
    path: "/mk/payment/register",
    component: _6339df32,
    name: "payment-register___mk"
  }, {
    path: "/mk/payment/status",
    component: _7443ad36,
    name: "payment-status___mk"
  }, {
    path: "/mk/payment/success",
    component: _00583aef,
    name: "payment-success___mk"
  }, {
    path: "/mn/coding/camp",
    component: _30e19abe,
    name: "coding-camp___mn"
  }, {
    path: "/mn/coding/creative",
    component: _09806d6c,
    name: "coding-creative___mn"
  }, {
    path: "/mn/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___mn"
  }, {
    path: "/mn/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___mn"
  }, {
    path: "/mn/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___mn"
  }, {
    path: "/mn/coding/junior",
    component: _4468f334,
    name: "coding-junior___mn"
  }, {
    path: "/mn/coding/pc",
    component: _5a493830,
    name: "coding-pc___mn"
  }, {
    path: "/mn/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___mn"
  }, {
    path: "/mn/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___mn"
  }, {
    path: "/mn/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___mn"
  }, {
    path: "/mn/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___mn"
  }, {
    path: "/mn/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___mn"
  }, {
    path: "/mn/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___mn"
  }, {
    path: "/mn/payment/register",
    component: _6339df32,
    name: "payment-register___mn"
  }, {
    path: "/mn/payment/status",
    component: _7443ad36,
    name: "payment-status___mn"
  }, {
    path: "/mn/payment/success",
    component: _00583aef,
    name: "payment-success___mn"
  }, {
    path: "/nl/coding/camp",
    component: _30e19abe,
    name: "coding-camp___nl"
  }, {
    path: "/nl/coding/creative",
    component: _09806d6c,
    name: "coding-creative___nl"
  }, {
    path: "/nl/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___nl"
  }, {
    path: "/nl/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___nl"
  }, {
    path: "/nl/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___nl"
  }, {
    path: "/nl/coding/junior",
    component: _4468f334,
    name: "coding-junior___nl"
  }, {
    path: "/nl/coding/pc",
    component: _5a493830,
    name: "coding-pc___nl"
  }, {
    path: "/nl/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___nl"
  }, {
    path: "/nl/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___nl"
  }, {
    path: "/nl/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___nl"
  }, {
    path: "/nl/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___nl"
  }, {
    path: "/nl/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___nl"
  }, {
    path: "/nl/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___nl"
  }, {
    path: "/nl/payment/register",
    component: _6339df32,
    name: "payment-register___nl"
  }, {
    path: "/nl/payment/status",
    component: _7443ad36,
    name: "payment-status___nl"
  }, {
    path: "/nl/payment/success",
    component: _00583aef,
    name: "payment-success___nl"
  }, {
    path: "/pl/coding/camp",
    component: _30e19abe,
    name: "coding-camp___pl"
  }, {
    path: "/pl/coding/creative",
    component: _09806d6c,
    name: "coding-creative___pl"
  }, {
    path: "/pl/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___pl"
  }, {
    path: "/pl/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___pl"
  }, {
    path: "/pl/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___pl"
  }, {
    path: "/pl/coding/junior",
    component: _4468f334,
    name: "coding-junior___pl"
  }, {
    path: "/pl/coding/pc",
    component: _5a493830,
    name: "coding-pc___pl"
  }, {
    path: "/pl/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___pl"
  }, {
    path: "/pl/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___pl"
  }, {
    path: "/pl/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___pl"
  }, {
    path: "/pl/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___pl"
  }, {
    path: "/pl/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___pl"
  }, {
    path: "/pl/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___pl"
  }, {
    path: "/pl/payment/register",
    component: _6339df32,
    name: "payment-register___pl"
  }, {
    path: "/pl/payment/status",
    component: _7443ad36,
    name: "payment-status___pl"
  }, {
    path: "/pl/payment/success",
    component: _00583aef,
    name: "payment-success___pl"
  }, {
    path: "/pt/coding/camp",
    component: _30e19abe,
    name: "coding-camp___pt"
  }, {
    path: "/pt/coding/creative",
    component: _09806d6c,
    name: "coding-creative___pt"
  }, {
    path: "/pt/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___pt"
  }, {
    path: "/pt/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___pt"
  }, {
    path: "/pt/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___pt"
  }, {
    path: "/pt/coding/junior",
    component: _4468f334,
    name: "coding-junior___pt"
  }, {
    path: "/pt/coding/pc",
    component: _5a493830,
    name: "coding-pc___pt"
  }, {
    path: "/pt/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___pt"
  }, {
    path: "/pt/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___pt"
  }, {
    path: "/pt/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___pt"
  }, {
    path: "/pt/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___pt"
  }, {
    path: "/pt/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___pt"
  }, {
    path: "/pt/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___pt"
  }, {
    path: "/pt/payment/register",
    component: _6339df32,
    name: "payment-register___pt"
  }, {
    path: "/pt/payment/status",
    component: _7443ad36,
    name: "payment-status___pt"
  }, {
    path: "/pt/payment/success",
    component: _00583aef,
    name: "payment-success___pt"
  }, {
    path: "/ro/coding/camp",
    component: _30e19abe,
    name: "coding-camp___ro"
  }, {
    path: "/ro/coding/creative",
    component: _09806d6c,
    name: "coding-creative___ro"
  }, {
    path: "/ro/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___ro"
  }, {
    path: "/ro/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___ro"
  }, {
    path: "/ro/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___ro"
  }, {
    path: "/ro/coding/junior",
    component: _4468f334,
    name: "coding-junior___ro"
  }, {
    path: "/ro/coding/pc",
    component: _5a493830,
    name: "coding-pc___ro"
  }, {
    path: "/ro/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___ro"
  }, {
    path: "/ro/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___ro"
  }, {
    path: "/ro/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___ro"
  }, {
    path: "/ro/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___ro"
  }, {
    path: "/ro/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___ro"
  }, {
    path: "/ro/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___ro"
  }, {
    path: "/ro/payment/register",
    component: _6339df32,
    name: "payment-register___ro"
  }, {
    path: "/ro/payment/status",
    component: _7443ad36,
    name: "payment-status___ro"
  }, {
    path: "/ro/payment/success",
    component: _00583aef,
    name: "payment-success___ro"
  }, {
    path: "/ru/coding/camp",
    component: _30e19abe,
    name: "coding-camp___ru"
  }, {
    path: "/ru/coding/creative",
    component: _09806d6c,
    name: "coding-creative___ru"
  }, {
    path: "/ru/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___ru"
  }, {
    path: "/ru/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___ru"
  }, {
    path: "/ru/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___ru"
  }, {
    path: "/ru/coding/junior",
    component: _4468f334,
    name: "coding-junior___ru"
  }, {
    path: "/ru/coding/pc",
    component: _5a493830,
    name: "coding-pc___ru"
  }, {
    path: "/ru/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___ru"
  }, {
    path: "/ru/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___ru"
  }, {
    path: "/ru/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___ru"
  }, {
    path: "/ru/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___ru"
  }, {
    path: "/ru/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___ru"
  }, {
    path: "/ru/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___ru"
  }, {
    path: "/ru/payment/register",
    component: _6339df32,
    name: "payment-register___ru"
  }, {
    path: "/ru/payment/status",
    component: _7443ad36,
    name: "payment-status___ru"
  }, {
    path: "/ru/payment/success",
    component: _00583aef,
    name: "payment-success___ru"
  }, {
    path: "/sk/coding/camp",
    component: _30e19abe,
    name: "coding-camp___sk"
  }, {
    path: "/sk/coding/creative",
    component: _09806d6c,
    name: "coding-creative___sk"
  }, {
    path: "/sk/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___sk"
  }, {
    path: "/sk/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___sk"
  }, {
    path: "/sk/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___sk"
  }, {
    path: "/sk/coding/junior",
    component: _4468f334,
    name: "coding-junior___sk"
  }, {
    path: "/sk/coding/pc",
    component: _5a493830,
    name: "coding-pc___sk"
  }, {
    path: "/sk/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___sk"
  }, {
    path: "/sk/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___sk"
  }, {
    path: "/sk/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___sk"
  }, {
    path: "/sk/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___sk"
  }, {
    path: "/sk/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___sk"
  }, {
    path: "/sk/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___sk"
  }, {
    path: "/sk/payment/register",
    component: _6339df32,
    name: "payment-register___sk"
  }, {
    path: "/sk/payment/status",
    component: _7443ad36,
    name: "payment-status___sk"
  }, {
    path: "/sk/payment/success",
    component: _00583aef,
    name: "payment-success___sk"
  }, {
    path: "/sq/coding/camp",
    component: _30e19abe,
    name: "coding-camp___sq"
  }, {
    path: "/sq/coding/creative",
    component: _09806d6c,
    name: "coding-creative___sq"
  }, {
    path: "/sq/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___sq"
  }, {
    path: "/sq/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___sq"
  }, {
    path: "/sq/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___sq"
  }, {
    path: "/sq/coding/junior",
    component: _4468f334,
    name: "coding-junior___sq"
  }, {
    path: "/sq/coding/pc",
    component: _5a493830,
    name: "coding-pc___sq"
  }, {
    path: "/sq/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___sq"
  }, {
    path: "/sq/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___sq"
  }, {
    path: "/sq/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___sq"
  }, {
    path: "/sq/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___sq"
  }, {
    path: "/sq/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___sq"
  }, {
    path: "/sq/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___sq"
  }, {
    path: "/sq/payment/register",
    component: _6339df32,
    name: "payment-register___sq"
  }, {
    path: "/sq/payment/status",
    component: _7443ad36,
    name: "payment-status___sq"
  }, {
    path: "/sq/payment/success",
    component: _00583aef,
    name: "payment-success___sq"
  }, {
    path: "/sr/coding/camp",
    component: _30e19abe,
    name: "coding-camp___sr"
  }, {
    path: "/sr/coding/creative",
    component: _09806d6c,
    name: "coding-creative___sr"
  }, {
    path: "/sr/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___sr"
  }, {
    path: "/sr/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___sr"
  }, {
    path: "/sr/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___sr"
  }, {
    path: "/sr/coding/junior",
    component: _4468f334,
    name: "coding-junior___sr"
  }, {
    path: "/sr/coding/pc",
    component: _5a493830,
    name: "coding-pc___sr"
  }, {
    path: "/sr/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___sr"
  }, {
    path: "/sr/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___sr"
  }, {
    path: "/sr/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___sr"
  }, {
    path: "/sr/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___sr"
  }, {
    path: "/sr/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___sr"
  }, {
    path: "/sr/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___sr"
  }, {
    path: "/sr/payment/register",
    component: _6339df32,
    name: "payment-register___sr"
  }, {
    path: "/sr/payment/status",
    component: _7443ad36,
    name: "payment-status___sr"
  }, {
    path: "/sr/payment/success",
    component: _00583aef,
    name: "payment-success___sr"
  }, {
    path: "/tg/coding/camp",
    component: _30e19abe,
    name: "coding-camp___tg"
  }, {
    path: "/tg/coding/creative",
    component: _09806d6c,
    name: "coding-creative___tg"
  }, {
    path: "/tg/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___tg"
  }, {
    path: "/tg/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___tg"
  }, {
    path: "/tg/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___tg"
  }, {
    path: "/tg/coding/junior",
    component: _4468f334,
    name: "coding-junior___tg"
  }, {
    path: "/tg/coding/pc",
    component: _5a493830,
    name: "coding-pc___tg"
  }, {
    path: "/tg/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___tg"
  }, {
    path: "/tg/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___tg"
  }, {
    path: "/tg/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___tg"
  }, {
    path: "/tg/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___tg"
  }, {
    path: "/tg/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___tg"
  }, {
    path: "/tg/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___tg"
  }, {
    path: "/tg/payment/register",
    component: _6339df32,
    name: "payment-register___tg"
  }, {
    path: "/tg/payment/status",
    component: _7443ad36,
    name: "payment-status___tg"
  }, {
    path: "/tg/payment/success",
    component: _00583aef,
    name: "payment-success___tg"
  }, {
    path: "/th/coding/camp",
    component: _30e19abe,
    name: "coding-camp___th"
  }, {
    path: "/th/coding/creative",
    component: _09806d6c,
    name: "coding-creative___th"
  }, {
    path: "/th/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___th"
  }, {
    path: "/th/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___th"
  }, {
    path: "/th/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___th"
  }, {
    path: "/th/coding/junior",
    component: _4468f334,
    name: "coding-junior___th"
  }, {
    path: "/th/coding/pc",
    component: _5a493830,
    name: "coding-pc___th"
  }, {
    path: "/th/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___th"
  }, {
    path: "/th/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___th"
  }, {
    path: "/th/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___th"
  }, {
    path: "/th/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___th"
  }, {
    path: "/th/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___th"
  }, {
    path: "/th/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___th"
  }, {
    path: "/th/payment/register",
    component: _6339df32,
    name: "payment-register___th"
  }, {
    path: "/th/payment/status",
    component: _7443ad36,
    name: "payment-status___th"
  }, {
    path: "/th/payment/success",
    component: _00583aef,
    name: "payment-success___th"
  }, {
    path: "/tr/coding/camp",
    component: _30e19abe,
    name: "coding-camp___tr"
  }, {
    path: "/tr/coding/creative",
    component: _09806d6c,
    name: "coding-creative___tr"
  }, {
    path: "/tr/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___tr"
  }, {
    path: "/tr/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___tr"
  }, {
    path: "/tr/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___tr"
  }, {
    path: "/tr/coding/junior",
    component: _4468f334,
    name: "coding-junior___tr"
  }, {
    path: "/tr/coding/pc",
    component: _5a493830,
    name: "coding-pc___tr"
  }, {
    path: "/tr/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___tr"
  }, {
    path: "/tr/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___tr"
  }, {
    path: "/tr/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___tr"
  }, {
    path: "/tr/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___tr"
  }, {
    path: "/tr/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___tr"
  }, {
    path: "/tr/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___tr"
  }, {
    path: "/tr/payment/register",
    component: _6339df32,
    name: "payment-register___tr"
  }, {
    path: "/tr/payment/status",
    component: _7443ad36,
    name: "payment-status___tr"
  }, {
    path: "/tr/payment/success",
    component: _00583aef,
    name: "payment-success___tr"
  }, {
    path: "/tt/coding/camp",
    component: _30e19abe,
    name: "coding-camp___tt"
  }, {
    path: "/tt/coding/creative",
    component: _09806d6c,
    name: "coding-creative___tt"
  }, {
    path: "/tt/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___tt"
  }, {
    path: "/tt/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___tt"
  }, {
    path: "/tt/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___tt"
  }, {
    path: "/tt/coding/junior",
    component: _4468f334,
    name: "coding-junior___tt"
  }, {
    path: "/tt/coding/pc",
    component: _5a493830,
    name: "coding-pc___tt"
  }, {
    path: "/tt/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___tt"
  }, {
    path: "/tt/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___tt"
  }, {
    path: "/tt/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___tt"
  }, {
    path: "/tt/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___tt"
  }, {
    path: "/tt/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___tt"
  }, {
    path: "/tt/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___tt"
  }, {
    path: "/tt/payment/register",
    component: _6339df32,
    name: "payment-register___tt"
  }, {
    path: "/tt/payment/status",
    component: _7443ad36,
    name: "payment-status___tt"
  }, {
    path: "/tt/payment/success",
    component: _00583aef,
    name: "payment-success___tt"
  }, {
    path: "/uk/coding/camp",
    component: _30e19abe,
    name: "coding-camp___uk"
  }, {
    path: "/uk/coding/creative",
    component: _09806d6c,
    name: "coding-creative___uk"
  }, {
    path: "/uk/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___uk"
  }, {
    path: "/uk/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___uk"
  }, {
    path: "/uk/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___uk"
  }, {
    path: "/uk/coding/junior",
    component: _4468f334,
    name: "coding-junior___uk"
  }, {
    path: "/uk/coding/pc",
    component: _5a493830,
    name: "coding-pc___uk"
  }, {
    path: "/uk/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___uk"
  }, {
    path: "/uk/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___uk"
  }, {
    path: "/uk/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___uk"
  }, {
    path: "/uk/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___uk"
  }, {
    path: "/uk/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___uk"
  }, {
    path: "/uk/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___uk"
  }, {
    path: "/uk/payment/register",
    component: _6339df32,
    name: "payment-register___uk"
  }, {
    path: "/uk/payment/status",
    component: _7443ad36,
    name: "payment-status___uk"
  }, {
    path: "/uk/payment/success",
    component: _00583aef,
    name: "payment-success___uk"
  }, {
    path: "/vi/coding/camp",
    component: _30e19abe,
    name: "coding-camp___vi"
  }, {
    path: "/vi/coding/creative",
    component: _09806d6c,
    name: "coding-creative___vi"
  }, {
    path: "/vi/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___vi"
  }, {
    path: "/vi/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___vi"
  }, {
    path: "/vi/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___vi"
  }, {
    path: "/vi/coding/junior",
    component: _4468f334,
    name: "coding-junior___vi"
  }, {
    path: "/vi/coding/pc",
    component: _5a493830,
    name: "coding-pc___vi"
  }, {
    path: "/vi/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___vi"
  }, {
    path: "/vi/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___vi"
  }, {
    path: "/vi/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___vi"
  }, {
    path: "/vi/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___vi"
  }, {
    path: "/vi/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___vi"
  }, {
    path: "/vi/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___vi"
  }, {
    path: "/vi/payment/register",
    component: _6339df32,
    name: "payment-register___vi"
  }, {
    path: "/vi/payment/status",
    component: _7443ad36,
    name: "payment-status___vi"
  }, {
    path: "/vi/payment/success",
    component: _00583aef,
    name: "payment-success___vi"
  }, {
    path: "/zh/coding/camp",
    component: _30e19abe,
    name: "coding-camp___zh"
  }, {
    path: "/zh/coding/creative",
    component: _09806d6c,
    name: "coding-creative___zh"
  }, {
    path: "/zh/coding/frontend",
    component: _2a8f2c0f,
    name: "coding-frontend___zh"
  }, {
    path: "/zh/coding/gamedesign",
    component: _4469dfa6,
    name: "coding-gamedesign___zh"
  }, {
    path: "/zh/coding/graphicdesign",
    component: _431f43c2,
    name: "coding-graphicdesign___zh"
  }, {
    path: "/zh/coding/junior",
    component: _4468f334,
    name: "coding-junior___zh"
  }, {
    path: "/zh/coding/pc",
    component: _5a493830,
    name: "coding-pc___zh"
  }, {
    path: "/zh/coding/pytpro",
    component: _9059bc82,
    name: "coding-pytpro___zh"
  }, {
    path: "/zh/coding/pytstart",
    component: _2de1b918,
    name: "coding-pytstart___zh"
  }, {
    path: "/zh/coding/unity",
    component: _b96b53a4,
    name: "coding-unity___zh"
  }, {
    path: "/zh/coding/videocontent",
    component: _613a64ca,
    name: "coding-videocontent___zh"
  }, {
    path: "/zh/coding/websites",
    component: _25cd19b5,
    name: "coding-websites___zh"
  }, {
    path: "/zh/payment/fail",
    component: _b9518d3c,
    name: "payment-fail___zh"
  }, {
    path: "/zh/payment/register",
    component: _6339df32,
    name: "payment-register___zh"
  }, {
    path: "/zh/payment/status",
    component: _7443ad36,
    name: "payment-status___zh"
  }, {
    path: "/zh/payment/success",
    component: _00583aef,
    name: "payment-success___zh"
  }, {
    path: "/af/*",
    component: _0d6e22d8,
    name: "all___af"
  }, {
    path: "/ca/*",
    component: _0d6e22d8,
    name: "all___ca"
  }, {
    path: "/da/*",
    component: _0d6e22d8,
    name: "all___da"
  }, {
    path: "/it/*",
    component: _0d6e22d8,
    name: "all___it"
  }, {
    path: "/et/*",
    component: _0d6e22d8,
    name: "all___et"
  }, {
    path: "/nl/*",
    component: _0d6e22d8,
    name: "all___nl"
  }, {
    path: "/bg/*",
    component: _0d6e22d8,
    name: "all___bg"
  }, {
    path: "/mn/*",
    component: _0d6e22d8,
    name: "all___mn"
  }, {
    path: "/th/*",
    component: _0d6e22d8,
    name: "all___th"
  }, {
    path: "/sr/*",
    component: _0d6e22d8,
    name: "all___sr"
  }, {
    path: "/sq/*",
    component: _0d6e22d8,
    name: "all___sq"
  }, {
    path: "/mk/*",
    component: _0d6e22d8,
    name: "all___mk"
  }, {
    path: "/hu/*",
    component: _0d6e22d8,
    name: "all___hu"
  }, {
    path: "/id/*",
    component: _0d6e22d8,
    name: "all___id"
  }, {
    path: "/kh/*",
    component: _0d6e22d8,
    name: "all___kh"
  }, {
    path: "/bs/*",
    component: _0d6e22d8,
    name: "all___bs"
  }, {
    path: "/hr/*",
    component: _0d6e22d8,
    name: "all___hr"
  }, {
    path: "/lt/*",
    component: _0d6e22d8,
    name: "all___lt"
  }, {
    path: "/vi/*",
    component: _0d6e22d8,
    name: "all___vi"
  }, {
    path: "/fr/*",
    component: _0d6e22d8,
    name: "all___fr"
  }, {
    path: "/sk/*",
    component: _0d6e22d8,
    name: "all___sk"
  }, {
    path: "/tg/*",
    component: _0d6e22d8,
    name: "all___tg"
  }, {
    path: "/kk/*",
    component: _0d6e22d8,
    name: "all___kk"
  }, {
    path: "/de/*",
    component: _0d6e22d8,
    name: "all___de"
  }, {
    path: "/tr/*",
    component: _0d6e22d8,
    name: "all___tr"
  }, {
    path: "/cs/*",
    component: _0d6e22d8,
    name: "all___cs"
  }, {
    path: "/ka/*",
    component: _0d6e22d8,
    name: "all___ka"
  }, {
    path: "/ro/*",
    component: _0d6e22d8,
    name: "all___ro"
  }, {
    path: "/uk/*",
    component: _0d6e22d8,
    name: "all___uk"
  }, {
    path: "/pt/*",
    component: _0d6e22d8,
    name: "all___pt"
  }, {
    path: "/zh/*",
    component: _0d6e22d8,
    name: "all___zh"
  }, {
    path: "/el/*",
    component: _0d6e22d8,
    name: "all___el"
  }, {
    path: "/tt/*",
    component: _0d6e22d8,
    name: "all___tt"
  }, {
    path: "/az/*",
    component: _0d6e22d8,
    name: "all___az"
  }, {
    path: "/he/*",
    component: _0d6e22d8,
    name: "all___he"
  }, {
    path: "/pl/*",
    component: _0d6e22d8,
    name: "all___pl"
  }, {
    path: "/cr/*",
    component: _0d6e22d8,
    name: "all___cr"
  }, {
    path: "/ar/*",
    component: _0d6e22d8,
    name: "all___ar"
  }, {
    path: "/cis/*",
    component: _0d6e22d8,
    name: "all___cis"
  }, {
    path: "/es/*",
    component: _0d6e22d8,
    name: "all___es"
  }, {
    path: "/ru/*",
    component: _0d6e22d8,
    name: "all___ru"
  }, {
    path: "/en/*",
    component: _0d6e22d8,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
