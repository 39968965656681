const middleware = {}

middleware['cpa'] = require('../middleware/cpa.js')
middleware['cpa'] = middleware['cpa'].default || middleware['cpa']

middleware['enLanguageRedirect'] = require('../middleware/enLanguageRedirect.js')
middleware['enLanguageRedirect'] = middleware['enLanguageRedirect'].default || middleware['enLanguageRedirect']

middleware['mainRedirect'] = require('../middleware/mainRedirect.js')
middleware['mainRedirect'] = middleware['mainRedirect'].default || middleware['mainRedirect']

middleware['middlewareUserUrl'] = require('../middleware/middlewareUserUrl.js')
middleware['middlewareUserUrl'] = middleware['middlewareUserUrl'].default || middleware['middlewareUserUrl']

export default middleware
