export const min = (array, prop, lessonsCount) => {
  if (array.length) {
    return array
      .filter((obj) => obj[prop])
      .reduce(
        (min, obj) => {
          return lessonsCount
            ? obj[prop] / obj.lessonsCount < min
              ? obj[prop] / obj.lessonsCount
              : min
            : obj[prop] < min
            ? obj[prop]
            : min
        },
        lessonsCount ? array[0][prop] / array[0].lessonsCount : array[0][prop]
      )
  }
}
