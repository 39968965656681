export default {
  common: {
    footer: {
      contacts: 'contactos',
      commonFooter: {
        sitemap: 'Sitemap',
      },
    },
    navBar: {
      menuFieldMain: 'Hogar',
      menuFieldContacts: 'Contactos',
      menuFieldCoding: 'Programación',
      menuFieldMath: 'Matemáticas',
      menuFieldSignUp: 'Enroll in a course',
      menuFieldCourses: 'Cursos',
      menuFieldAbout: 'Sobre nosotros',

      menuFieldVenues: 'Venues',
      menuFieldAllCourses: 'All Courses',
      menuFieldLearning: 'What we teach',
      menuFieldLearningFormat: 'Learning Format',
      menuFieldFaq: 'Preguntas más frecuentes',
      menuFieldPlatform: 'Platform',
      menuFieldPlatformAndMethodology: 'Платформа и методология',
      menuFieldLessonsFormat: 'Class format',
      menuFieldTeachers: 'Teachers',
      menuFieldReviews: 'Reviews',
      menuFieldStudentsProjects: 'Student projects',
      menuFieldSummerCamp: 'Summer sessions',
    },
    applicationFormSection: {
      requestSentSuccessMessage: 'Request is sent',
      requestSentFailMessage: 'Error sending request',
      personalInformationAgreement: 'By clicking, you agree to',
      personalInformationAgreementLink: 'the personal info protection policy',
      privacyPolicyAgreement: 'I agree with',
      privacyPolicyAgreementLink: 'the privacy policy',
      confirmMailingFeedText:
        'I would like to receive information about the courses.',
      customFieldPlaceholder: '',
      customFieldError: 'Necesario',
    },
    companyCopyright: 'Algorithmics',
  },
  coding: {
    course: 'Programming courses',
  },
  modals: {
    thanksModal: {
      title: 'Thank you for your application.',
      descriptions: [
        'We commend you for your interest in our courses. The letter with presentation is already sent to your email. Our manager will contact you and answer all your questions soon.',
        'Follow us in social media to know more.',
      ],
    },
    congratulations:
      'Gracias por su interés en el curso de programación. <span>Ya se ha enviado un correo electrónico con la presentación a su dirección de correo electrónico.</span> En un futuro cercano, el gerente se comunicará con usted y responderá todas sus preguntas.',
    offer: 'In the meantime, you can read reviews about us on social networks:',
  },
  cityBillet: '¿Quieres ir al sitio de Algorithmics en esta ciudad?',
  cityNoticed: 'Notamos que tu ciudad',
  yes: 'Sí',
  no: 'No',
  wantStudy: '¡Queremos aprender de ti!',
  pages: {
    contacts: {
      title: 'Contactos',
      metaDescription: 'Algorithmic`s Contactos',
      offerLinkTitle: 'Oferta de contrato ',
    },
  },
  payment: {
    errors: {
      unknown: 'Something went wrong.',
      invoiceCompleted: 'The invoice is already paid.',
      invoiceExpired: 'The invoice is expired.',
      cancelled:
        'Your invoice has not been paid. Get in touch with your manager, please',
      cancelledByTimeout:
        'Your invoice has not been paid. Get in touch with your manager, please',
      missingInvoiceId: 'Missing Invoice ID',
    },
    successMessage: 'We will send you a payment confirmation to your email.',
    thanks: 'Thank you!',
    waitForRedirect: 'You will be redirected to a payment page...',
    status: 'Payment status',
    processingMessage: 'Your payment is being processing. Please, wait',
  },
}
