export default (scripts) => ({
  meta: scripts.metaTags.map((item) => ({
    name: item.name,
    hid: item.name,
    content: item.content,
  })),
  script: scripts.scripts
    .map((item) => {
      const result = {
        innerHTML: item.body,
        body: item.location === 'body',
        defer: item.type === 'defer',
        async: item.type === 'async',
      }

      if (item.htmlId) {
        result.id = item.htmlId
      }

      return result
    })
    .concat(
      ...scripts.linkToScripts.map((item) => ({
        defer: item.type === 'defer',
        async: item.type === 'async',
        src: item.link,
        body: item.location === 'body',
      }))
    ),
  noscript: scripts.noScripts.map((item) => ({
    innerHTML: item.noscript,
    body: true,
  })),
})
