/* eslint-disable */
import Vue from 'vue'
import Inputmask from 'inputmask'
import masks from '../const/masks'
import { defaultMask, unmask } from '@/utils/mask/phone-mask'

const inputEvent = new Event('input', { bubbles: true })

Vue.directive('inputmask', {
  /**
   *
   * @param {*} el
   * @param {*} binding - binding.value - bool
   *                      binding.arg - если 'static',то отключаем
   */
  update(el, binding) {
    if (binding.arg !== 'static' && binding.value !== false) {
      const event = new Event('input', { bubbles: true })
      const unmaskValue = unmask(el.value)
      if (unmaskValue.length < 1) {
        const im = new Inputmask(defaultMask)
        $nuxt.$emit(binding.arg, 'ru')
        im.mask(el)
      } else {
        const filtredMask = masks.filter((item) =>
          unmaskValue.startsWith(unmask(item.mask))
        )
        if (!filtredMask.length && unmaskValue.length > 2) {
          el.value = unmaskValue.slice(0, -1)
          el.dispatchEvent(inputEvent)
        } else if (filtredMask.length) {
          const im = new Inputmask({
            mask: filtredMask.map((item) => item.mask.replace(/[0-9]/g, 9)),
          })
          $nuxt.$emit(binding.arg, filtredMask[0].cc.toLocaleLowerCase())
          im.mask(el)
        }
      }
    }
  },

  bind(el, binding) {
    if (binding.value !== false) {
      const im = new Inputmask(
        binding.arg === 'static' ? binding.value : defaultMask
      )
      im.mask(el)
      el.addEventListener(
        'paste',
        (e) => {
          el.value = e.clipboardData.getData('text')
          el.dispatchEvent(event)
        },
        { passive: true }
      )
    }
  },
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.event, { passive: true })
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.event)
  },
})
