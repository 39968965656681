/* eslint-disable */
import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initMailRuRating', function(tmrMetricId) {
    const pluginName = 'MailRuRatingMetric_' + tmrMetricId
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      if (process.env.NODE_ENV !== 'production') return
      // metric code
      const _tmr = window._tmr || (window._tmr = []);
      _tmr.push({ id: tmrMetricId, type: 'pageView', start: new Date().getTime() })
      ;(function(d, w, id) {
        if (d.getElementById(id)) return
        const ts = d.createElement('script');
        ts.type = 'text/javascript'
        ts.async = true
        ts.id = id
        ts.src =
          (d.location.protocol === 'https:' ? 'https:' : 'http:') +
          '//top-fwz1.mail.ru/js/code.js'
        const f = function() {
          const s = d.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(ts, s)
        };
        if (w.opera === '[object Opera]') {
          d.addEventListener('DOMContentLoaded', f, false)
        } else {
          f()
        }
      })(document, window, 'topmailru-code')
      app.router.afterEach((to, from) => {
        // send again after router page changed?
      })
    }
    })
}
