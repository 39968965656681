/* eslint-disable */
import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initGoogleAnalytics', function(gaMetric) {
    const pluginName = 'googleAnaliticsMetric_' + gaMetric
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      /*
       ** Only run on client-side and only in production mode
       */
      if (
        process.env.NODE_ENV !== 'production' ||
        gaMetric === null ||
        gaMetric === undefined
      )
        return /*
       ** Include Google Analytics Script
       */
          ;(function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r
        ;(i[r] =
          i[r] ||
          function () {
            ;(i[r].q = i[r].q || []).push(arguments)
          }),
          (i[r].l = 1 * new Date())
        ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.src = g
        m.parentNode.insertBefore(a, m)
      })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
      )
      /*
       ** Set the current page
       */
      if (window.ga) {
        window.ga('create', gaMetric, 'auto')
      }
      /*
       ** Every time the route changes (fired on initialization too)
       */
      app.router.afterEach((to, from) => {
        /*
         ** We tell Google Analytics to add a `pageview`
         */
        if (window.ga) {
          window.ga('set', 'page', to.fullPath)
          window.ga('send', 'pageview')
        }
      })
    }
  })
}
