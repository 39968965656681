export default function ({ store, redirect, route }) {
  const routePath = route.path

  if (routePath.includes('/mailing')) {
    return redirect('/privacy#mail_agreement')
  }

  if (routePath.includes('/cookie')) {
    return redirect('/privacy#cookies')
  }

  const alwaysAvailablePaths = [
    '/payment',
    '/sitemap',
    '/contacts',
    '/mailing',
    '/privacy',
  ]
  if (alwaysAvailablePaths.some((item) => routePath.includes(item))) {
    return
  }

  if (!store.getters['landings/landingIsActive']) {
    return redirect('/')
  }

  if (routePath === '/coding/visual') {
    return redirect('/coding/creative')
  }
}
