export default {
  common: {
    footer: {
      contacts: 'Контакты',
    },
    citySelector: {
      cityNotFound: 'Город не найден',
      cityPlaceholder: 'Поиск города',
    },
    navBar: {
      menuFieldMain: 'Главная',
      menuFieldContacts: 'Контакты',
      menuFieldCoding: 'Программирование',
      menuFieldMath: 'Математика',
      menuFieldSignUp: 'Записаться на курс',
      menuFieldCourses: 'Курсы',
      menuFieldAbout: 'О нас',
      menuFieldVenues: 'Площадки',
      menuFieldPlatform: 'Платформа',
      menuFieldPlatformAndMethodology: 'Платформа и методология',
      menuFieldAllCourses: 'Все курсы',
      menuFieldLearning: 'Чему научим',
      menuFieldLearningFormat: 'Формат обучения',
      menuFieldLessonsFormat: 'Формат занятий',
      menuFieldTeachers: 'Преподаватели',
      menuFieldReviews: 'Отзывы',
      menuFieldStudentsProjects: 'Проекты учеников',
      menuFieldSummerCamp: 'Летние смены',
      menuFieldFaq: 'FAQ',
    },
    applicationFormSection: {
      requestSentSuccessMessage:
        'Заявка отправлена. <br />Скоро мы свяжемся с вами',
      requestSentFailMessage: 'Ой, что-то пошло не так :( <br />Позвоните нам',
      personalInformationAgreement: 'Нажимая, вы соглашаетесь с',
      personalInformationAgreementLink:
        'положением о защите персональных данных',
      privacyPolicyAgreement: 'Соглашаюсь с',
      privacyPolicyAgreementLink: 'политикой конфиденциальности',
      confirmMailingFeedText: 'Хочу получать информацию о курсах',
      namePlaceholder: 'Имя',
      phonePlaceholder: 'Телефон',
      mailPlaceholder: 'Электронная почта',
      classPlaceholder: 'Класс',
      customFieldPlaceholder: '',
      customFieldError: 'Необходимо указать',
    },
    modals: {
      thanks: 'Спасибо',
      fail: 'Ой, что то пошло не так',
      congratulations:
        'Благодарим вас за интерес, проявленный к курсу программирования. <span>Вам на почту уже отправлено письмо с презентацией.</span> В ближайшее время с вами свяжется менеджер и ответит на все интересующие вас вопросы.',
    },
    companyCopyright: 'Алгоритмика',
  },
  coding: {
    course: 'Курсы программирования',
  },
  modals: {
    thanksModal: {
      title: 'Спасибо за заявку',
      descriptions: [
        'Благодарим вас за интерес, проявленный к нашим курсам. Вам на почту уже отправлено письмо с презентацией. В ближайшее время с вами свяжется менеджер и ответит на все интересующие вопросы.',
        'А пока вы можете почитать о нас отзывы в социальных сетях:',
      ],
    },
    congratulations:
      'Благодарим вас за интерес, проявленный к курсу программирования. <span>Вам на почту уже отправлено письмо с презентацией.</span> В ближайшее время с вами свяжется менеджер и ответит на все интересующие вас вопросы.',
    offer: 'А пока вы можете почитать о нас отзывы в социальных сетях:',
  },
  cityBillet: 'Хотите перейти на сайт Алгоритмики в этом городе?',
  cityNoticed: 'Мы заметили, что ваш город',
  yes: 'Да',
  no: 'Нет',
  wantStudy: 'Хотим у вас учиться!',
  pages: {
    contacts: {
      title: 'Контакты',
      metaDescription: 'Контакты Алгоритмики',
      offerLinkTitle: 'Договор оферты',
    },
  },
  payment: {
    errors: {
      unknown: 'Ой! Что-то пошло не так',
      invoiceCompleted: 'Этот счёт уже оплачен',
      invoiceExpired: 'Срок действия ссылки на оплату истёк',
      cancelled: 'Ваш счёт не оплачен. Свяжитесь с вашим менеджером',
      cancelledByTimeout: 'Ваш счёт не оплачен. Свяжитесь с вашим менеджером',
      missingInvoiceId: 'Не указан номер счета',
    },
    successMessage:
      'В ближайшее время на указанный вами адрес будут отправлены чеки и письмо с подтверждением оплаты',
    thanks: 'Спасибо!',
    waitForRedirect: 'Подождите. Идет перенаправление на платежную страницу...',
    status: 'Статус платежа',
    processingMessage:
      'Информация по вашему счету обрабатывается, нужно немного подождать',
  },
}
