import Vue from 'vue'
import eventBus from '@/utils/eventBus'
import {
  ADVERTISING_CAMPAIGN_BRANCH_CODE,
  BRANCH_CODE_MAIN,
  MOSCOW_FEDERAL_BRANCH_CODE,
} from '@/const/branch'

export default ({ app, store }) => {
  function sendEvent(eventName) {
    if (window.dataLayer) {
      window.dataLayer.push({ event: eventName })
      // eslint-disable-next-line no-console
      console.group('Metric with dataLayer(gtm) send event')
      // eslint-disable-next-line no-console
      console.info('ID: ' + store.state.landings.metrics.gaMetric)
      // eslint-disable-next-line no-console
      console.info('EventName: ' + eventName)
      // eslint-disable-next-line no-console
      console.groupEnd()
    }

    if (window.ga) {
      window.ga('send', 'event', eventName)
      // eslint-disable-next-line no-console
      console.group('GA send event')
      // eslint-disable-next-line no-console
      console.info('ID: ' + store.state.landings.metrics.gaMetric)
      // eslint-disable-next-line no-console
      console.info('EventName: ' + eventName)
      // eslint-disable-next-line no-console
      console.groupEnd()
    }

    if (window._tmr) {
      window._tmr.push({
        id: store.state.landings.metrics.tmrMetricId,
        type: 'reachGoal',
        goal: eventName,
      })
      // eslint-disable-next-line no-console
      console.group('Mail send event')
      // eslint-disable-next-line no-console
      console.info('ID: ' + store.state.landings.metrics.tmrMetricId)
      // eslint-disable-next-line no-console
      console.info('EventName: ' + eventName)
      // eslint-disable-next-line no-console
      console.groupEnd()
    }

    if (window.ym) {
      window.ym(store.state.landings.metrics.yaMetricId, 'reachGoal', eventName)
      // eslint-disable-next-line no-console
      console.group('Yandex send event')
      // eslint-disable-next-line no-console
      console.info('ID: ' + store.state.landings.metrics.yaMetricId)
      // eslint-disable-next-line no-console
      console.info('EventName: ' + eventName)
      // eslint-disable-next-line no-console
      console.groupEnd()
    }

    if (window.fbq) {
      window.fbq('track', 'Lead')
      // eslint-disable-next-line no-console
      console.group('Facebook send event')
      // eslint-disable-next-line no-console
      console.info('ID: ' + store.state.landings.metrics.fbMetricId)
      // eslint-disable-next-line no-console
      console.info('EventName: ' + eventName)
      // eslint-disable-next-line no-console
      console.groupEnd()
    }
  }

  if (!Vue.analyticEvents) {
    Vue.analyticEvents = true
    if (process.env.NODE_ENV !== 'production') return

    eventBus.$on('send-form-submit', (data) => {
      const branchId = store.state.landings.mainSettings.branch_id
      const isSuitableBranch =
        branchId === BRANCH_CODE_MAIN ||
        branchId === ADVERTISING_CAMPAIGN_BRANCH_CODE ||
        branchId === MOSCOW_FEDERAL_BRANCH_CODE

      if (isSuitableBranch && window.dataLayer) {
        window.dataLayer.push({ event: 'form_submit' })
      }

      if (window.fbq) {
        window.fbq('track', 'Lead')
      }

      sendEvent('order_sent')
    })

    eventBus.$on('click-phone-number', () => {
      sendEvent('phone_click')
    })

    eventBus.$on('click-signup-button', () => {
      sendEvent('signup_click')
    })
  }
}
