/* eslint-disable */
import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initVkontakteMetric', function(vkMetricId) {
    const pluginName = 'vkontakteMetric_' + vkMetricId
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      if (
        process.env.NODE_ENV !== 'production' ||
        vkMetricId === null ||
        vkMetricId === undefined
      )
        return
      // metric code
      !(function () {
        const t = document.createElement('script')
        ;(t.type = 'text/javascript'),
          (t.async = !0),
          (t.src = 'https://vk.com/js/api/openapi.js?156'),
          (t.onload = function () {
            VK.Retargeting.Init(vkMetricId), VK.Retargeting.Hit()
          }),
          document.head.appendChild(t)
      })()
      app.router.afterEach((to, from) => {
        // send again after router page changed?
      })
    }
  })
}
