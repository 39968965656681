export const IP_DETECT_SERVICE_URL = '/api/info/geo'
export const API_GDPR_GET_DECISION = '/api/gdpr/get-decision'
export const API_GDPR_SET_DECISION = '/api/gdpr/set-decision'

export function encodeFingerprint(data) {
  try {
    return Buffer.from(JSON.stringify(data)).toString('base64')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error)
  }
}

export function prepareGeoData(response, state) {
  const geoData = { ...state.gdpr.fingerprint.geo }
  if (response.data.city) {
    for (const key in state.gdpr.fingerprint.geo) {
      if (response.data[key]) {
        geoData[key] = response.data[key]
      }
    }
  }

  return geoData
}

export function prepareBrowserData(navigator, state) {
  const browser = { ...state.gdpr.fingerprint.browser }
  for (const key in state.gdpr.fingerprint.browser) {
    if (navigator[key]) {
      browser[key] = navigator[key]
    }
  }

  return browser
}
