export const exclusiveSettingsName = [
  'map_code',
  'payment_options',
  'company_details',
  'company_info',
  'return_policy',
  'mail_agreement',
  'custom_metric',
  'custom_metric2',
  'yandexMapIframe',
  'privacy_agreement',
  'privacy_kids',
  'terms_of_use',
  'arbitrary_document',
  'cookies',
  // pictures objects
  'joinOfferScreenJoinScreenPicture',
  'campOfferLink',
  'footerMenuImageFileSkolkovo',
  'officialInfoGeneralInfo',
  'officialInfoStructure',
  'officialInfoDocuments',
  'officialInfoEducation',
  'officialInfoRequirements',
  'officialInfoEmployees',
  'officialInfoObjects',
  'officialInfoGrants',
  'officialInfoPaidServices',
  'officialInfoFinance',
  'officialInfoVacant',
  'officialInfoAccessibleEnvironment',
  'officialInfoInternationalAgreements',
  'officialInfoAdditional1',
  'officialInfoAdditional2',
]
