import sanitize from '@/utils/sanitaze/sanitaze'
import { exclusiveSettingsName } from '@/utils/sanitaze/exclusiveSettingsName'
import { nonDecodedSettings } from '@/utils/sanitaze/nonDecodedSettings'
const Entities = require('html-entities').AllHtmlEntities

/**
 *
 * @param {Object} settings
 * @param {string} key
 * @returns {Promise<void>|string|string|*}
 */
export function getSetting(settings, key) {
  try {
    if (Object.keys(settings).includes(key) && settings[key].length !== 0) {
      let content = settings[key][0]
      if (isNaN(content)) {
        content = prepareContent(content, key)
      }

      return content
    }

    return ''
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unknown key text setting: ' + key, [], [settings[key], error])
  }
}

/**
 *
 * @param {Object} settings
 * @param {string} key
 * @returns {*[]|*}
 */
export function getBullets(settings, key) {
  try {
    if (Object.keys(settings).includes(key) && settings[key].length !== 0) {
      return settings[key]
    }

    return []
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unknown key bullets: ' + key, [], [settings[key], error])
  }
}

/**
 *
 * @param {Object} settings
 * @param {string} key
 * @returns {number}
 */
export function getBulletsCount(settings, key) {
  try {
    if (Object.keys(settings).includes(key) && settings[key].length !== 0) {
      return settings[key].length
    }

    return 0
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unknown key bullets: ' + key, [], [settings[key], error])
  }
}

function prepareContent(content, key) {
  if (!nonDecodedSettings.includes(key)) {
    content = Entities.decode(content)
  }

  if (exclusiveSettingsName.includes(key)) {
    return content
  }
  return sanitize(content)
}
