export const summerShiftYoungRuCourse = {
  route: 'summer-shift-young',
  id: 1411,
  locale: ['ru', 'cis'],
}

export const summerShiftRuCourse = {
  route: 'summer-shift',
  id: 1413,
  locale: ['ru', 'cis'],
}

export const visualCodingRuCourse = {
  route: 'visualcoding',
  id: 810,
  locale: ['ru', 'cis'],
}

export const visualCodingEnCourse = {
  route: 'visualcoding',
  id: 275,
  locale: ['en'],
}

export const gameDesignRuCourse = {
  route: 'gamedesign',
  id: 707,
  locale: ['ru', 'cis'],
}

export const gameDesignEnCourse = {
  route: 'gamedesign',
  id: 902,
  locale: ['en'],
}

export const webcreateRuCourse = {
  route: 'webcreate',
  id: 716,
  locale: ['ru', 'cis'],
}

export const pytStartRuCourse = {
  route: 'pytstart',
  id: 686,
  locale: ['ru', 'cis'],
}

export const pytStartEnCourse = {
  route: 'pytstart',
  id: 361,
  locale: ['en'],
}

export const pytProRuCourse = {
  route: 'pytpro',
  id: 734,
  locale: ['ru', 'cis'],
}

export const pytProEnCourse = {
  route: 'pytpro',
  id: 539,
  locale: ['en'],
}

export const mathYoungRuCourse = {
  route: 'math-young',
  id: 739,
  locale: ['ru', 'cis'],
}

export const mathRuCourse = {
  route: 'math',
  id: 740,
  locale: ['ru', 'cis'],
}

export const literacyRuCourse = {
  route: 'literacy',
  id: 729,
  locale: ['ru', 'cis'],
}

export const literacyEnCourse = {
  route: 'literacy',
  id: 801,
  locale: ['en'],
}
