export default {
  data() {
    return {
      viewportWidth: process.client ? window.innerWidth : 770,
    }
  },
  mounted() {
    this.updateViewportWidth()
    window.addEventListener('resize', this.updateViewportWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewportWidth)
  },
  methods: {
    updateViewportWidth() {
      this.viewportWidth = window?.innerWidth
    },
  },
}
