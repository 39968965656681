/* eslint-disable */
import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initYandexMetric', function(yaMetricId) {
    const pluginName = 'yandexMetric_' + yaMetricId
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      if (process.env.NODE_ENV !== 'production') return // metrics code
        ;(function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            ;(m[i].a = m[i].a || []).push(arguments)
          }
        m[i].l = 1 * new Date()
        ;(k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a)
      })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

      if (window.ym) {
        window.ym(yaMetricId, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        })
      }

      app.router.afterEach((to, from) => {
        // send again after router page changed?
      })
    }
  })
}
