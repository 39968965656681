export default [
  {
    viewCurrency: '€',
    backCurrency: 'EUR',
    name: 'Евро',
  },
  {
    viewCurrency: '₽',
    backCurrency: 'RUB',
    name: 'Рубли',
  },
  {
    viewCurrency: '$',
    backCurrency: 'USD',
    name: 'Доллар',
  },
]
