import { getSetting, getBullets, getBulletsCount } from '@/utils/settings'
import { min } from '@/utils/minPrice'
import headParser from '@/utils/headParser'
import currencyParser from '@/utils/currencyParser'
import { addDays } from '@/utils/date/date'
import courses from '@/const/courses'
import { summerShiftYoungRuCourse, summerShiftRuCourse } from '@/const/course'
import {
  SET_ERROR,
  SET_SETTINGS,
  SET_MAIN_SETTINGS,
  SET_TEACHERS,
  SET_VENUES,
  SET_DOMAINS,
  SET_CURRENT_DOMAINS_DATA,
  SET_FEATURE_TOGGLE_DATA,
  SET_DEFAULT_SETTINGS,
  SET_COURSES,
  SET_GTM_KEY,
  SET_GROUPS,
  SET_SUITABLE_DOMAIN_DATA,
  SET_YA_ID,
  SET_FB_ID,
  SET_VK_ID,
  SET_GA_ID,
  SET_TMR_ID,
  SET_PREPARED_HEAD,
  SET_AVAILABLE_LANGUAGES,
  SET_LANGUAGE_CODE,
} from '@/store/mutationsConstants'

const state = () => ({
  settings: {},
  mainSettings: {},
  defaultSettings: {},
  metrics: {
    gtmMetricId: '',
    yaMetricId: '',
    tmrMetricId: '',
    vkMetricId: '',
    fbMetricId: '',
    gaMetric: '',
  },
  groups: [],
  teachers: [],
  venues: [],
  currentDomainData: {},
  domains: [],
  courses: [],
  featureToggles: {},
  suitableDomainData: null,
  preparedHead: {
    meta: [],
    script: [],
    noscript: [],
  },
  availableLanguageCodes: [],
  error: false,
  routePath: '/',
  languageCode: '',
})

const getters = {
  landings: (state) => state,
  settings: (state) => state.settings,
  landingIsActive: (state) => {
    return state.settings.active ? !!state.settings.active[0] : false
  },
  dateSale: () => (locale, type) => {
    const startDate = new Date('2021-05-20').getTime()
    const now = new Date().getTime()
    const diffDays = Math.floor((now - startDate) / (1000 * 60 * 60 * 24))
    if (type === 'date') {
      return new Date(
        new Date(addDays(14 - (diffDays % 14))).setHours(0, 0, 0, 0)
      )
    }
    return new Date(addDays(14 - (diffDays % 14))).toLocaleString(
      locale === 'en' ? 'en' : 'ru',
      {
        day: 'numeric',
        month: 'long',
      }
    )
  },
  isEmptyGroups: (state) => !state.groups.length,
  isWorldFranchise: (state) => {
    return state.mainSettings.isWorldFranchise
      ? state.mainSettings.isWorldFranchise[0]
      : false
  },
  isRussianFranchise: (state) => {
    return state.mainSettings.isRussianFranchise
      ? state.mainSettings.isRussianFranchise[0]
      : false
  },
  currency: (state) =>
    currencyParser(!!state.groups.length && state.groups[0].currency),
  minCoursePrice: (state) => Math.round(min(state.groups, 'minPrice') / 100),
  featureEnabled: (state) => (key) =>
    state.featureToggles[key] ? state.featureToggles[key] === true : false,
  featureDisabled: (state) => (key) =>
    state.featureToggles[key] ? state.featureToggles[key] === false : true,
  infoWindow: (state) =>
    state.settings._infoWindowData ? state.settings._infoWindowData[0] : {},
  mainSettings: (state) => state.mainSettings,
  error: (state) => state.error,
  textSetting: (state) => (key) => {
    const setting = getSetting(state.settings, key)
    if (setting !== undefined && setting !== '') {
      return setting
    }

    return getSetting(state.defaultSettings, key)
  },
  gtmMetricId: (state) => state.metrics.gtmMetricId,
  yaMetricId: (state) => state.metrics.yaMetricId,
  tmrMetricId: (state) => state.metrics.tmrMetricId,
  textMainSetting: (state) => (key) => {
    const noLeadOfficeValueReplacement = ['yandexMapIframe']

    const setting = getSetting(state.mainSettings, key)
    if (setting !== undefined && setting !== '') {
      return setting
    }

    if (noLeadOfficeValueReplacement.includes(key)) {
      return setting
    }

    return getSetting(state.defaultSettings, key)
  },
  bulletsSettings: (state) => (key) => {
    const bullets = getBullets(state.settings, key)
    if (bullets.length) {
      return bullets
    }

    return getBullets(state.defaultSettings, key)
  },
  bulletsMainSettings: (state) => (key) => {
    const bullets = getBullets(state.mainSettings, key)
    if (bullets.length) {
      return bullets
    }

    return getBullets(state.defaultSettings, key)
  },
  bulletsCount: (state) => (key) => getBulletsCount(state.settings, key),
  teachers: (state) => state.teachers,
  venues: (state) => state.venues,
  currentDomainData: (state) => state.currentDomainData,
  domains: (state, getters) => {
    if (getters.isWorldFranchise) {
      return state.domains.filter((domain) => domain.isWorldFranchise === true)
    }

    if (getters.isRussianFranchise) {
      return state.domains.filter(
        (domain) => domain.isRussianFranchise === true
      )
    }
  },
  courses: (state) => state.courses,
  suitableDomainData: (state) => state.suitableDomainData,
  routePath: (state) => state.routePath,
  currentLanguageCode: (state) => state.languageCode,
  availableLanguageCodes: (state, getters) => {
    let routePathWithoutLocale = getters.routePath.substr(3)
    if (routePathWithoutLocale === '') {
      routePathWithoutLocale = '/'
    }
    const localesWithDuplicate = state.availableLanguageCodes[
      routePathWithoutLocale
    ].map((item, index, array) => item.substr(0, 2))

    return [...new Set(localesWithDuplicate)]
  },
}

const mutations = {
  setRoutePath(state, routePath) {
    state.routePath = routePath
  },
  [SET_LANGUAGE_CODE](state, languageCode) {
    state.languageCode = languageCode
  },
  [SET_SETTINGS](state, settings) {
    state.settings = settings
  },
  [SET_MAIN_SETTINGS](state, settings) {
    state.mainSettings = settings
  },
  [SET_DEFAULT_SETTINGS](state, settings) {
    state.defaultSettings = settings
  },
  [SET_FEATURE_TOGGLE_DATA](state, features) {
    state.featureToggles = features
  },
  [SET_TEACHERS](state, teachers) {
    state.teachers = teachers
  },
  [SET_VENUES](state, venues) {
    state.venues = venues
  },
  [SET_DOMAINS](state, domains) {
    state.domains = domains
  },
  [SET_COURSES](state, courses) {
    state.courses = courses
  },
  [SET_CURRENT_DOMAINS_DATA](state, currentDomainData) {
    state.currentDomainData = currentDomainData
  },
  [SET_GTM_KEY](state, gtmMetricId) {
    state.metrics.gtmMetricId = gtmMetricId
  },
  [SET_YA_ID](state, yaId) {
    state.metrics.yaMetricId = yaId
  },
  [SET_VK_ID](state, vkMetricId) {
    state.metrics.vkMetricId = vkMetricId
  },
  [SET_FB_ID](state, fbMetricId) {
    state.metrics.fbMetricId = fbMetricId
  },
  [SET_GA_ID](state, gaMetric) {
    state.metrics.gaMetric = gaMetric
  },
  [SET_TMR_ID](state, tmrMetric) {
    state.metrics.tmrMetric = tmrMetric
  },
  [SET_GROUPS](state, groups) {
    state.groups = groups
  },
  [SET_ERROR](state, error) {
    state.error = error
  },
  [SET_SUITABLE_DOMAIN_DATA](state, suitableDomainData) {
    state.suitableDomainData = suitableDomainData
  },
  [SET_PREPARED_HEAD](state, preparedHead) {
    state.preparedHead = preparedHead
  },
  [SET_PREPARED_HEAD](state, preparedHead) {
    state.preparedHead = preparedHead
  },
  [SET_AVAILABLE_LANGUAGES](state, availableLanguageCodes) {
    state.availableLanguageCodes = availableLanguageCodes
  },
}

const actions = {
  landingInit({ commit }, response) {
    try {
      if (response.item?.mainSettings?.gtm_metric?.length) {
        commit(SET_GTM_KEY, response.item.mainSettings.gtm_metric[0])
      }
      if (response.item?.mainSettings?.yandex_metric?.length) {
        commit(SET_YA_ID, response.item.mainSettings.yandex_metric[0])
      }
      if (response.item?.mainSettings?.tmr_metric?.length) {
        commit(SET_TMR_ID, response.item.mainSettings.tmr_metric[0])
      }
      if (response.item?.mainSettings?.fb_metric?.length) {
        commit(SET_FB_ID, response.item.mainSettings.fb_metric[0])
      }
      if (response.item?.mainSettings?.ga_metric?.length) {
        commit(SET_GA_ID, response.item.mainSettings.ga_metric[0])
      }
      if (response.item?.mainSettings?.vk_metric?.length) {
        commit(SET_VK_ID, response.item.mainSettings.vk_metric[0])
      }
      commit(SET_ERROR, false)
      commit(SET_SETTINGS, response.item.settings)
      commit(SET_MAIN_SETTINGS, response.item.mainSettings)
      commit(SET_DEFAULT_SETTINGS, response.item.defaultSettings)
      commit(SET_TEACHERS, response.item.teachers)
      commit(SET_VENUES, response.item.venues)
      commit(SET_DOMAINS, response.item.domains)
      commit(SET_COURSES, response.item.courses)
      commit(SET_CURRENT_DOMAINS_DATA, response.item.currentDomainData)
      commit(SET_FEATURE_TOGGLE_DATA, response.item.features)
      commit(SET_SUITABLE_DOMAIN_DATA, response.item.suitableDomainData)
      commit(SET_LANGUAGE_CODE, response.item.languageCode)
      if (response.item?.mainSettings?.customScriptsAndMeta?.length) {
        const preparedHead = headParser(
          response.item.mainSettings.customScriptsAndMeta[0]
        )
        commit(SET_PREPARED_HEAD, preparedHead)
      }
      commit(SET_AVAILABLE_LANGUAGES, response.item.availableLanguageCodes)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
  async getGroups({ commit }, { branchId, inapiUrl, route, locale }) {
    const discountSummerShift = 27
    try {
      const { data } = await this.$axios.$get(
        `${inapiUrl}/v1/online-payment/list`,
        {
          params: {
            branchId,
          },
        }
      )
      const onlinePaymentsGroups = data.items
        .filter(
          (group) =>
            group.courseId ===
            courses.find(
              (course) =>
                course.route === route && course.locale.includes(locale)
            ).id
        )
        .map((group, index) => {
          group.index = index
          group.minPrice = min(group.detailedPrices, 'price', true)
          group.detailedPrices.map((detailPrice, detailIndex) => {
            detailPrice.index = detailIndex
            detailPrice.discount = 0
            if (detailIndex > 0) {
              detailPrice.discount = (detailIndex + 1) * 5
            }
            if (
              [summerShiftYoungRuCourse.id, summerShiftRuCourse.id].some(
                (item) => item === group.courseId
              )
            ) {
              detailPrice.discount = discountSummerShift
            }
            return detailPrice
          })
          return group
        })
      commit(SET_GROUPS, onlinePaymentsGroups)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  },
  setRoutePath({ commit }, routePath) {
    commit('setRoutePath', routePath)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
