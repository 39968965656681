// store/popUp.js
export const state = () => ({
  isVisible: false,
})

export const mutations = {
  setVisibility(state, visibility) {
    state.isVisible = visibility
  },
}

export const actions = {
  showPopUp({ commit }) {
    commit('setVisibility', true)
  },
  hidePopUp({ commit }) {
    commit('setVisibility', false)
  },
  togglePopUp({ commit, state }) {
    commit('setVisibility', !state.isVisible)
  },
}

export const getters = {
  isPopUpVisible: (state) => state.isVisible,
}
