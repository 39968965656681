import sanitizeHtml from 'sanitize-html'

export default function sanitize(dirtyHtml) {
  return sanitizeHtml(dirtyHtml, {
    allowedTags: ['br', 'span', 'b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      a: ['href'],
    },
    allowedIframeHostnames: ['www.youtube.com'],
  })
}
