export const SET_ERROR = 'setError'
export const SET_SETTINGS = 'setSettings'
export const SET_MAIN_SETTINGS = 'setMainSettings'
export const SET_DEFAULT_SETTINGS = 'setDefaultSettings'
export const SET_TEACHERS = 'setTeachers'
export const SET_VENUES = 'setVenues'
export const SET_DOMAINS = 'setDomains'
export const SET_COURSES = 'setCourses'
export const SET_CURRENT_DOMAINS_DATA = 'setCurrentDomainData'
export const SET_FEATURE_TOGGLE_DATA = 'setFeatureToggle'
export const SET_GTM_KEY = 'setGtmKey'
export const SET_VK_ID = 'setVkId'
export const SET_YA_ID = 'setYaId'
export const SET_TMR_ID = 'setTmrId'
export const SET_FB_ID = 'setFbId'
export const SET_GA_ID = 'setGaId'
export const SET_GROUPS = 'setGroups'
export const SET_SUITABLE_DOMAIN_DATA = 'setSuitableDomainData'
export const SET_GDPR_FINGERPRINT = 'setGdprFingerprint'
export const SET_GDPR_DECISIONS = 'setGdprDecisions'
export const SET_GDPR_SAVED = 'setGdprSaved'
export const SET_PREPARED_HEAD = 'setPreparedHead'
export const SET_AVAILABLE_LANGUAGES = 'setAvailableLanguageCodes'
export const SET_LANGUAGE_CODE = 'setLanguageCode'
