import Vue from 'vue'

export default () => {
  if (!Vue.getTextSettingChecker) {
    Vue.getTextSettingChecker = true
    Vue.mixin({
      methods: {
        $getTextSetting(key, defaultValue = '') {
          if (!this.$store) {
            return defaultValue
          }
          const value = this.$store.getters['landings/textSetting'](key)
          if (!value) {
            return defaultValue
          }
          return value
        },
        $getBullets(key, defaultValue = []) {
          return this.$store.getters['landings/bulletsSettings'](key)
        },
        $getTextMainSetting(key, defaultValue = '') {
          if (!this.$store) {
            return defaultValue
          }
          const value = this.$store.getters['landings/textMainSetting'](key)
          if (!value) {
            return defaultValue
          }
          return value
        },
        $getBulletsMainSetting(key, defaultValue = []) {
          return this.$store.getters['landings/bulletsMainSettings'](key)
        },
        $getBulletsCount(key) {
          return this.$store.getters['landings/bulletsCount'](key)
        },
        $getTeachers() {
          return this.$store.getters['landings/teachers']
        },
        $getVenues() {
          return this.$store.getters['landings/venues']
        },
        $getCurrentDomainData() {
          return this.$store.getters['landings/currentDomainData']
        },
        $getDomains() {
          return this.$store.getters['landings/domains']
        },
        $getCourses() {
          return this.$store.getters['landings/courses']
        },
        $translateMenuText(code, defaultValue) {
          return this.$getTextMainSetting(
            code,
            this.$t('components.common.navBar.' + code) || defaultValue
          )
        },
        $translateFormText(code, defaultValue) {
          return this.$getTextMainSetting(
            code,
            this.$t('components.common.applicationFormSection.' + code) ||
              defaultValue
          )
        },
      },
    })
  }
}
