/* eslint-disable */
import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initFbPixel', function (fbMetricId) {
    const pluginName = 'facebookMetric_' + fbMetricId
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      if (
        process.env.NODE_ENV !== 'production' ||
        fbMetricId === null ||
        fbMetricId === undefined
      )
        return

      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )

      if (window.fbq) {
        window.fbq('init', fbMetricId)
      }

      app.router.afterEach((to, from) => {
        // send again after router page changed
        if (window.fbq) {
          window.fbq('track', 'PageView')
        }
      })
    }
  })
}
