export default function ({ app, route }) {
  const cpaCookieOpts = {
    path: '/',
    maxAge: 60 * 60 * 24 * 90,
  }

  if (process.env.NODE_ENV === 'production') {
    if (route.host === 'probniyurok.algoritmika.org') {
      cpaCookieOpts.domain = 'probniyurok.algoritmika.org'
    }
  }

  app.$cookies.set('cpa_initial_query', route.query, cpaCookieOpts)

  if (route.query.admitad_uid !== undefined) {
    app.$cookies.set('cpa_param', 'admitad_uid', cpaCookieOpts)
    app.$cookies.set('cpa_value', route.query.admitad_uid, cpaCookieOpts)
  }

  if (
    route.query.utm_source !== undefined &&
    route.query.utm_source === 'edpartners'
  ) {
    app.$cookies.set('cpa_param', 'edpartners', cpaCookieOpts)
    app.$cookies.set('cpa_value', route.query.cl_uid, cpaCookieOpts)
  }
}
