export default {
  common: {
    footer: {
      contacts: 'Contacts',
      commonFooter: {
        sitemap: 'Sitemap',
      },
    },
    navBar: {
      menuFieldMain: 'Home',
      menuFieldContacts: 'Contacts',
      menuFieldCoding: 'Programming',
      menuFieldMath: 'Mathematics',
      menuFieldSignUp: 'Enroll in a course',
      menuFieldCourses: 'Courses',
      menuFieldAbout: 'About us',
      menuFieldVenues: 'Venues',
      menuFieldAllCourses: 'All Courses',
      menuFieldLearning: 'What we teach',
      menuFieldLearningFormat: 'Learning Format',
      menuFieldFaq: 'FAQ',
      menuFieldPlatform: 'Platform',
      menuFieldPlatformAndMethodology: 'Платформа и методология',
      menuFieldLessonsFormat: 'Class format',
      menuFieldTeachers: 'Teachers',
      menuFieldReviews: 'Reviews',
      menuFieldStudentsProjects: 'Student projects',
      menuFieldSummerCamp: 'Summer sessions',
    },
    applicationFormSection: {
      requestSentSuccessMessage: 'Request is sent',
      requestSentFailMessage: 'Error sending request',
      personalInformationAgreement: 'By clicking, you agree to',
      personalInformationAgreementLink: 'the personal info protection policy',
      privacyPolicyAgreement: 'I agree with',
      privacyPolicyAgreementLink: 'the privacy policy',
      confirmMailingFeedText:
        'I would like to receive information about the courses.',
      customFieldPlaceholder: '',
      customFieldError: 'Required',
    },
    companyCopyright: 'Algorithmics',
  },
  coding: {
    course: 'Programming courses',
  },
  modals: {
    thanksModal: {
      title: 'Thank you for your application.',
      descriptions: [
        'We commend you for your interest in our courses. The letter with presentation is already sent to your email. Our manager will contact you and answer all your questions soon.',
        'Follow us in social media to know more.',
      ],
    },
    congratulations:
      'Thank you for your interest in the programming course. <span>An email with the presentation has already been sent to your email.</span> In the near future, the manager will contact you and answer all your questions.',
    offer: 'In the meantime, you can read reviews about us on social networks:',
  },
  cityBillet: 'Do you want to go to the site of Algorithmics in this city?',
  cityNoticed: 'We noticed that your city',
  yes: 'Yes',
  no: 'No',
  wantStudy: 'We want to learn from you!',
  pages: {
    contacts: {
      title: 'Contacts',
      metaDescription: 'Algorithmic`s contacts',
      offerLinkTitle: 'Contract offer',
    },
  },
  payment: {
    errors: {
      unknown: 'Something went wrong.',
      invoiceCompleted: 'The invoice is already paid.',
      invoiceExpired: 'The invoice is expired.',
      cancelled:
        'Your invoice has not been paid. Get in touch with your manager, please',
      cancelledByTimeout:
        'Your invoice has not been paid. Get in touch with your manager, please',
      missingInvoiceId: 'Missing Invoice ID',
    },
    successMessage:
      'In the near future, checks and a letter confirming payment will be sent to the address you specified',
    thanks: 'Thank you!',
    waitForRedirect: 'You will be redirected to a payment page...',
    status: 'Payment status',
    processingMessage: 'Your payment is being processing. Please, wait',
  },
}
