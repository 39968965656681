export const state = () => ({
  orderCall: {
    name: '',
    phone: '',
    email: '',
    newsletter: true,
    privacyPolicy: true,
  },
})

export const mutations = {
  setOrderCall(state, orderCall) {
    state.orderCall = orderCall
  },
}

export const actions = {
  async fetch({ commit }, orderCall) {
    // console.log('fetch called with:', orderCall);

    // Преобразуем setTimeout в промис
    await new Promise((resolve) => setTimeout(resolve, 1000))

    commit('setOrderCall', orderCall) // обновляем состояние
    // console.log('orderCall:', orderCall); // выводим данные в консоль
  },
}

export const getters = {
  orderCall: (state) => state.orderCall,
}
