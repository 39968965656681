import {
  summerShiftYoungRuCourse,
  summerShiftRuCourse,
  visualCodingRuCourse,
  visualCodingEnCourse,
  gameDesignRuCourse,
  gameDesignEnCourse,
  webcreateRuCourse,
  pytStartRuCourse,
  pytStartEnCourse,
  pytProRuCourse,
  pytProEnCourse,
  mathYoungRuCourse,
  mathRuCourse,
  literacyRuCourse,
  literacyEnCourse,
} from './course'

export default [
  summerShiftYoungRuCourse,
  summerShiftRuCourse,
  visualCodingRuCourse,
  visualCodingEnCourse,
  gameDesignRuCourse,
  gameDesignEnCourse,
  webcreateRuCourse,
  pytStartRuCourse,
  pytStartEnCourse,
  pytProRuCourse,
  pytProEnCourse,
  mathYoungRuCourse,
  mathRuCourse,
  literacyRuCourse,
  literacyEnCourse,
]
