export const actions = {
  async nuxtServerInit({ dispatch }, { redirect, req, route, i18n }) {
    const url = req.headers.host + route.path
    if (route.path !== '/healthcheck' && route.path !== '/payment/register') {
      const response = await this.$axios.$get('api/init', {
        params: { url, locale: route.path.substring(1, 3) },
      })
      if (response.errorCode === '302') {
        const routePath = route.path.substring(3)

        return redirect(response.context.redirectUrl + routePath)
      }

      dispatch('landings/setRoutePath', route.path)
      dispatch('landings/landingInit', response)
      if (response.item.languageCode) {
        i18n.setLocale(response.item.languageCode.substring(0, 2))
      }
    }
  },
}
