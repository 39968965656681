import Vue from 'vue'

export default ({ app, store }, inject) => {
  inject('initGoogleTagManager', function (gtmKey) {
    const pluginName = 'googleTagManagerMetric_' + gtmKey
    if (!Vue[pluginName]) {
      Vue[pluginName] = true
      if (
        process.env.NODE_ENV !== 'production' ||
        gtmKey === null ||
        gtmKey === undefined
      )
        return
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        // eslint-disable-next-line eqeqeq
        const dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', gtmKey)
    }
  })
}
